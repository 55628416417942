import React from "react";
import { useForm } from "react-hook-form";
import Input from "../../../../components/FormComponents/Input";
import useMyAccount from "../../../../hooks/useMyAccount";
import userIcon from "../../../../data/user_icon.jpg";

const UserProfile = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const iconStyle = { color: "#EC1F25" };
  const { userData } = useMyAccount();

  return (
    <div>
      <div className="ltn-author-introducing clearfix">
        <div className="author-img">
          <img
            src={
              userData?.image
                ? `data:image/png;base64,${userData?.image}`
                : userIcon
            }
            alt="Author"
          />
        </div>
        <div className="author-info">
          <h6>Gerente geral</h6>
          <h2 style={{ color: "#071C1F" }}>{userData?.user_name}</h2>
          <div className="footer-address">
            <ul>
              <li>
                <div className="footer-address-icon">
                  <i className="fab fa-whatsapp" style={iconStyle} />
                </div>
                <div className="footer-address-info">
                  <p>
                    <a
                      href={`https://wa.me/${userData?.whatsapp}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {userData?.whatsapp}
                    </a>
                  </p>
                </div>
              </li>
              <li>
                <div className="footer-address-icon">
                  <i className="far fa-envelope" style={iconStyle} />
                </div>
                <div className="footer-address-info">
                  <p>
                    <a href={`mailto:${userData?.email}`}>{userData?.email}</a>
                  </p>
                </div>
              </li>
              <li>
                <div className="footer-address-icon">
                  <i className="fas fa-briefcase" style={iconStyle} />
                </div>
                <div className="footer-address-info">
                  <p>
                    {userData?.company_name
                      ? userData.company_name
                      : "Não informado"}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <form id="contact-form" action="mail.php" method="post">
        <div className="row">
          <div className="col-md-6 mb-3">
            <h8>Nome completo</h8>
            <div className="input-item input-item-lock ltn__custom-icon">
              <Input
                type="text"
                register={register}
                fieldName="name"
                placeholder={userData?.user_name}
                errors={errors}
                disabled
              />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <h8>Função</h8>
            <div className="input-item input-item-lock ltn__custom-icon">
              <Input
                type="text"
                register={register}
                fieldName="contactFunction"
                placeholder={userData?.function}
                errors={errors}
                disabled
              />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <h8>Whatsapp</h8>
            <div className="input-item input-item-lock ltn__custom-icon">
              <Input
                type="text"
                register={register}
                fieldName="phone"
                placeholder={userData?.whatsapp}
                errors={errors}
                disabled
              />
            </div>
          </div>
          <div className="col-md-6">
            <h8>Telefone</h8>
            <div className="input-item input-item-lock ltn__custom-icon">
              <Input
                type="text"
                register={register}
                fieldName="phone"
                placeholder={userData?.phone}
                errors={errors}
                disabled
              />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <h8>E-mail</h8>
            <div className="input-item input-item-lock ltn__custom-icon">
              <Input
                type="email"
                register={register}
                fieldName="email"
                placeholder={userData?.email}
                errors={errors}
                disabled
              />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <h8>Empresa</h8>
            <div className="input-item input-item-lock ltn__custom-icon">
              <Input
                type="text"
                register={register}
                fieldName="companyName"
                placeholder={userData?.company_name}
                errors={errors}
                disabled
              />
            </div>
          </div>
        </div>
        <p className="form-messege mb-0 mt-20" />
      </form>
    </div>
  );
};

export default UserProfile;
