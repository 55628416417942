import React from "react";
import CompanyProfile from "./CompanyProfile";
import CompanyDetails from "./CompanyDetails";

const MyAccountDetailsCompany = () => {
  return (
    <div className="tab-pane fade active show">
      <div className="ltn__myaccount-tab-content-inner">
        <p>Minha empresa</p>
      </div>
      <CompanyProfile showDetailsButton={false} />
      <CompanyDetails />
    </div>
  );
};

export default MyAccountDetailsCompany;
