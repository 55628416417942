import React from "react";
import { Link } from "react-router-dom";
import SideCartItem from "./components/SideCartItem";
import useCart from "../../hooks/useCart";
import VoidCartList from "../Cart/components/VoidCartList";
import { formatBRL } from "../../util/formatPriceBRL";
import { useAuth } from "../../hooks/useAuth";

const SideCart = ({ handleCloseCart, isOpen }) => {
  const { cart, totalValue } = useCart();
  const { userInfo } = useAuth();

  const isPurchaseDisabled = () => {
    return totalValue() >= userInfo?.user_buying_limit;
  };
  return (
    <>
      <div
        onMouseLeave={handleCloseCart}
        id="ltn__utilize-cart-menu "
        className={`ltn__utilize ltn__utilize-cart-menu ${
          isOpen ? "expanded" : ""
        }`}
        style={{
          boxShadow: "0 0 50px 0 rgba(46, 56, 220, 0.2)",
        }}
      >
        <div className="ltn__utilize-menu-inner ltn__scrollbar">
          {isPurchaseDisabled() && (
            <div className="mini-cart-sub-total">
              <i class="fa-solid fa-triangle-exclamation ltn__secondary-color"></i>{" "}
              <strong>
                Limite de compra excedido! Por favor remova items do carrinho
                para prosseguir com a compra!
              </strong>
            </div>
          )}
          <div className="ltn__utilize-menu-head">
            <span className="ltn__utilize-menu-title">Carrinho</span>
            <button onClick={handleCloseCart} className="ltn__utilize-close">
              ×
            </button>
          </div>
          {cart.length === 0 ? (
            <VoidCartList />
          ) : (
            <div className="mini-cart-product-area ltn__scrollbar">
              {cart.map((e) => (
                <SideCartItem key={e.product_id} obj={e} />
              ))}
            </div>
          )}
          {cart.length !== 0 && (
            <div className="mini-cart-footer">
              <div className="mini-cart-sub-total">
                <h5>
                  Subtotal: <span>{formatBRL(totalValue())}</span>
                </h5>
              </div>
              <div className="mini-cart-sub-total">
                <strong>
                  Limite de Compra:{" "}
                  <span>{formatBRL(userInfo?.user_buying_limit)}</span>
                </strong>
              </div>

              <div className="btn-wrapper go-top">
                <Link to="/cart" className="theme-btn-1 btn btn-effect-1 p-4">
                  Ver Carrinho
                </Link>
                <Link
                  to={!isPurchaseDisabled() && "/checkout"}
                  className="theme-btn-2 btn btn-effect-2 d-flex justify-content-center p-4"
                >
                  Checkout
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default SideCart;
