import React from "react";

const VoidCartList = () => {
  return (
    <div>
      <h4 className="ltn__primary-color text-center">
        Seu carrinho está vazio!
      </h4>
    </div>
  );
};
export default VoidCartList;
