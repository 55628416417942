import React from "react";
import { Menu } from "./components/Menu";
import { Outlet } from "react-router-dom";

const MyAccount = () => {
  return (
    <div className="liton__wishlist-area pb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__product-tab-area">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="ltn__tab-menu-list mb-50">
                      <Menu />
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="tab-content">
                      <Outlet />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
