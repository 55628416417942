import React from "react";
import styles from "./Collaborators.module.css";
import { useState } from "react";
import KeywordFilter from "../KeywordFilter";
import { Link } from "react-router-dom";
import useMyAccount from "../../../../hooks/useMyAccount";
import userImage from "../../../../data/user_icon.jpg";

export const Collaborators = () => {
  const [keyword, setKeyword] = useState("");
  const { collaborators } = useMyAccount();
  console.log(collaborators);
  const iconStyle = { color: "#EC1F25" };
  const buttonContainerStyle = { display: "flex", gap: "10px" };

  const handleEmailClick = async (email) => {
    window.location.href = `mailto:${email}`;
  };

  const handlePhoneClick = async (phoneNumber) => {
    const whatsappLink = `https://wa.me/${phoneNumber}`;
    window.location.href = whatsappLink;
  };

  const handleFilterChange = (event) => {
    setKeyword(event.target.value);
  };
  return (
    <div className="ltn__my-properties-table table-responsive">
      <>
        <KeywordFilter keyword={keyword} onFilterChange={handleFilterChange} />
        {/* <OrderFilter keyword={keyword} onFilterChange={handleFilterChange} /> */}
        <table className="table" style={{ marginTop: "20px" }}>
          <thead
            style={{
              borderRight: "hidden",
              borderLeft: "hidden",
              borderTop: "hidden",
            }}
          >
            <tr>
              <th scope="col">Unidade</th> <th scope="col" />
              <th scope="col" style={{ textAlign: "center" }}>
                Ver perfis das Unidades
              </th>
            </tr>
          </thead>
          <tbody
            style={{
              borderRight: "hidden",
              borderLeft: "hidden",
              fontSize: "14px",
            }}
          >
            {collaborators
              .filter((collaborator) =>
                collaborator.full_name.includes(keyword)
              )
              .map((collaborator, index) => (
                <tr className="mb-5" key={index}>
                  <td
                    style={{
                      width: "180px",
                    }}
                  >
                    <img
                      src={
                        collaborator.image
                          ? `data:image/png;base64,${collaborator?.image}`
                          : userImage
                      }
                      alt="Author"
                      style={{
                        width: "164px",
                        height: "124px",
                        objectFit: "cover",
                      }}
                    />
                  </td>
                  <td>
                    <h2 className={styles.nameCollaborator}>
                      {collaborator?.full_name}
                    </h2>
                    {collaborator.function ? (
                      <p className={styles.description}>
                        {collaborator?.function} em{" "}
                        <span style={{ color: "#EC1F25", fontWeight: "700" }}>
                          {collaborator?.company_name}
                        </span>
                      </p>
                    ) : (
                      <p className={styles.description}>Não informado</p>
                    )}
                    <div className="footer-address">
                      <div
                        className="button-container"
                        style={buttonContainerStyle}
                      >
                        <Link to={"collabDetails"} state={{ collaborator }}>
                          <button
                            className="theme-btn-1 btn btn-block"
                            type="submit"
                            style={{
                              padding: "8px 4px",
                              minWidth: "180px",
                              boxShadow: "var(--ltn__box-shadow-1)",
                              border: "none",
                            }}
                          >
                            <i className="fas fa-search" />
                            Ver detalhes
                          </button>
                        </Link>
                        <button
                          className={styles.btn}
                          onClick={() =>
                            handlePhoneClick(collaborator.whatsapp)
                          }
                        >
                          <i className="fab fa-whatsapp" style={iconStyle} />
                        </button>
                        <button
                          className={styles.btn}
                          onClick={() => handleEmailClick(collaborator.email)}
                        >
                          <i className="far fa-envelope" style={iconStyle} />
                        </button>
                      </div>
                    </div>
                  </td>
                  <td style={{ textAlign: "center" }}></td>
                </tr>
              ))}
          </tbody>
        </table>
      </>
    </div>
  );
};
