import React from "react";
import { whatsapp_links } from "../../data/contact";

export default function CallToActonV3() {
  return (
    <div>
      <div
        style={{
          backgroundImage: `url(/assets/img/bg/6.jpg)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="ltn__call-to-action-area ltn__call-to-action-4 pt-115 pb-120"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="call-to-action-inner call-to-action-inner-4 text-center">
                <h3 className="section-subtitle --ltn__primary-color1">
                  Faça sua cotação ligando para 
                  <br></br>
                  <strong>
                  (51) 3438-2127
                  </strong>
                  <br></br>
                  ou
                </h3>
                <div className="btn-wrapper mt-0">
                  <a
                    href={whatsapp_links.geral}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="theme-btn-1 btn btn-effect-1 btn-cta"
                  >
                    <i class="fab fa-whatsapp"></i>
                    <span>Chamar pelo Whatsapp</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ltn__call-to-4-img-2">
          <img src={"/assets/img/bg/11.png"} alt="#" />
        </div>
      </div>
    </div>
  );
}
