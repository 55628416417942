import React from "react";
import { Link } from "react-router-dom";

import CartTableHeader from "./CartTableHeader";
import CartTableItem from "./CartTableItem";
import useCart from "../../../hooks/useCart";
import VoidCartList from "./VoidCartList";

const CartTable = () => {
  const { cart } = useCart();

  return (
    <>
      {cart.length === 0 ? (
        <div className="row ">
          <div className="col-12 text-center my-5 ">
            <VoidCartList />
          </div>
          <button className="theme-btn-1 btn btn-effect-1 col-12">
            <Link to="/products">Conheça nossos produtos</Link>
          </button>
        </div>
      ) : (
        <table className="shoping-cart-table table-responsive justify-content-center">
          <CartTableHeader />
          <tbody className="text-align align-center">
            {cart.map((e) => (
              <CartTableItem key={e.product_name} obj={e} />
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};
export default CartTable;
