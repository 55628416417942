import React from "react";
import useProduct from "../../../hooks/useProduct";
import ProductItem from "./ProductItem";

const ProductList = () => {
  const {
    products = [],
    isLoading,
    productQuery,
    setSearch,
    search,
  } = useProduct();

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearch(value);
  };
  return (
    <div className="col-lg-9 col-sm-12">
      <div className="input-item mb-30">
        <Input
          type="text"
          placeholder="Pesquisa"
          value={search}
          onChange={handleSearch}
          onClickX={() => setSearch("")}
        />
      </div>
      <div>
        <div className="row">
          {productQuery.total_pages === 0 ? (
            <h1 style={{ color: "red", textAlign: "center" }}>
              Nenhum produto encontrado!
            </h1>
          ) : isLoading ? (
            <h4 style={{ color: "gray", textAlign: "center" }}>
              Carregando Produtos...
            </h4>
          ) : (
            products.map((e) => <ProductItem key={e.product_id} {...e} />)
          )}
        </div>
      </div>
    </div>
  );
};
export default ProductList;

export const Input = (props) => {
  return (
    <div style={{ display: "flex" }}>
      <input {...props} />
      <button
        style={{
          background: "transparent",
          position: "relative",
          top: 2,
          right: 90,
          border: "none",
          padding: 0,
          margin: 0,
        }}
      >
        <i onClick={props.onClickX} class="fa-solid fa-xmark"></i>
      </button>
      <div
        onClick={props.onClickX}
        style={{ position: "relative", right: 60, top: 20, color: "black" }}
      >
        <i class="fa-solid fa-magnifying-glass"></i>
      </div>
    </div>
  );
};
