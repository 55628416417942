import React, { useState } from "react";
import styles from "../../MyAccount.module.css";
import { Link } from "react-router-dom";

const VerificationCodeForm = ({ onVerifyCode }) => {
  const [code, setCode] = useState("");

  return (
    <div className="ltn__myaccount-tab-content-inner">
      <div className="account-login-inner">
        <form action="#" className="ltn__form-box contact-form-box">
          <h5 className="title-2 mb-30">
            Código de alteração enviado para você!
            <p className={styles.description}>
              Insira o código enviado para seu e-mail para alterar sua senha.
            </p>
          </h5>
          <input
            type="text"
            name="code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="Código*"
            className="mb-15"
          />
          <div className="btn-wrapper mt-0">
            <Link to={"changePassword"}>
              <button className="theme-btn-1 btn btn-block">
                Alterar senha
              </button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VerificationCodeForm;
