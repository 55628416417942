import React from "react";
import CartTable from "./CartTable";

const Cart = () => {
  return (
    <div>
      <h2 className=" ltn__primary-color title-2 ">Itens Relacionados</h2>
      <CartTable />
    </div>
  );
};
export default Cart;
