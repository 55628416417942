import React from "react";

const KeywordFilter = ({ keyword, onFilterChange, onSearchClick }) => {
  return (
    <div style={{ position: "relative", borderRadius: "none" }}>
      <input
        type="text"
        id="filter"
        value={keyword}
        onChange={onFilterChange}
        placeholder="Pesquise por nome, unidade ou cargo"
        style={{ borderRadius: "0" }}
      />
      <button
        className="fas fa-search"
        style={{
          position: "absolute",
          right: "0",
          top: "50%",
          transform: "translateY(-50%)",
          height: "100%",
          backgroundColor: "var(--ltn__secondary-color)",
          border: "none",
          color: "#fff",
          width: "60px",
        }}
        onClick={onSearchClick}
      ></button>
    </div>
  );
};

export default KeywordFilter;
