import React, { useCallback, memo } from "react";
import { Link } from "react-router-dom";
import useProduct from "../../../../hooks/useProduct";

const Pagination = () => {
  const { productQuery, setPage } = useProduct();

  const decreasePageHandler = () => {
    setPage((prev) => prev - 1);
  };
  const increasePageHandler = () => {
    setPage((prev) => prev + 1);
  };

  const changePageHandler = (index) => {
    setPage(index);
  };

  const isFirstPage = useCallback(
    () => productQuery.actual_page === 1,
    [productQuery.actual_page]
  );
  const isLastPage = useCallback(
    () => productQuery.actual_page === productQuery.total_pages,
    [productQuery.actual_page, productQuery.total_pages]
  );
  const isLessEqualThan4Pages = useCallback(
    () => productQuery.total_pages <= 4,
    [productQuery.total_pages]
  );
  const isLessEqualThan3Pages = useCallback(
    () => productQuery.total_pages <= 3,
    [productQuery.total_pages]
  );
  const isLessEqualThan2Pages = useCallback(
    () => productQuery.total_pages <= 2,
    [productQuery.total_pages]
  );
  return (
    <>
      {productQuery.total_pages > 1 && (
        <div
          style={{ marginBottom: "10px" }}
          className="ltn__pagination-area text-center"
        >
          {/* {arraySection[0].page} */}
          <div className="ltn__pagination">
            <ul>
              {!isFirstPage() && (
                <li>
                  <Link onClick={decreasePageHandler}>
                    <i className="fas fa-angle-double-left" />
                  </Link>
                </li>
              )}
              {productQuery.actual_page !== 1 && !isLessEqualThan3Pages() && (
                <li className={productQuery.actual_page === 1 ? "active" : ""}>
                  <Link onClick={() => changePageHandler(1)}>1</Link>
                </li>
              )}
              <>
                {/* botão 1 */}
                <li className={productQuery.actual_page ? "active" : ""}>
                  <Link
                    onClick={() => changePageHandler(productQuery.actual_page)}
                  >
                    {productQuery.actual_page}
                  </Link>
                </li>
                {/* botão 2 */}
                {!(productQuery.actual_page + 1 > productQuery.total_pages) && (
                  <li>
                    <Link
                      onClick={() =>
                        changePageHandler(productQuery.actual_page + 1)
                      }
                    >
                      {productQuery.actual_page + 1}
                    </Link>
                  </li>
                )}
                {/* Botão 3 */}
                {!isLessEqualThan2Pages() &&
                  !(
                    productQuery.actual_page + 2 >
                    productQuery.total_pages
                  ) && (
                    <li e={productQuery.actual_page + 2 && "active"}>
                      <Link
                        onClick={() =>
                          changePageHandler(productQuery.actual_page + 2)
                        }
                      >
                        {productQuery.actual_page + 2}
                      </Link>
                    </li>
                  )}
              </>
              {!isLessEqualThan3Pages() && (
                <>
                  {!(productQuery.actual_page === productQuery.total_pages) &&
                    !(
                      productQuery.actual_page + 1 ===
                      productQuery.total_pages
                    ) &&
                    !(
                      productQuery.actual_page + 2 ===
                      productQuery.total_pages
                    ) && (
                      <li
                        className={
                          productQuery.actual_page === productQuery.total_pages
                            ? "active"
                            : ""
                        }
                      >
                        <Link
                          onClick={() =>
                            changePageHandler(productQuery.total_pages)
                          }
                        >
                          {productQuery.total_pages}
                        </Link>
                      </li>
                    )}
                  {!isLessEqualThan4Pages() && !isLastPage() && (
                    <li>
                      <Link onClick={increasePageHandler}>
                        <i className="fas fa-angle-double-right" />
                      </Link>
                    </li>
                  )}
                </>
              )}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};
export default memo(Pagination);
