import React, { useState } from "react";
import GetUserCode from "./GetUserCode";
import ValidateCode from "./ValidateCode";
import ChangePassword from "./ChangePassword";
import Finalization from "./Finalization";

const ResetPassword = () => {
  const [step, setStep] = useState("1");
  const [emailSelected, setEmailSelected] = useState("");
  const [userId, setUserId] = useState("");
  const emailHandler = (email) => {
    setEmailSelected(email);
  };
  const stepHandler = (step) => {
    setStep(step);
  };
  const userIdHandler = (userId) => {
    setUserId(userId);
  };

  const resetPasswordStepEnum = {
    1: <GetUserCode stepHandler={stepHandler} emailHandler={emailHandler} />,
    2: (
      <ValidateCode
        stepHandler={stepHandler}
        emailSelected={emailSelected}
        userIdHandler={userIdHandler}
      />
    ),
    3: <ChangePassword stepHandler={stepHandler} userId={userId} />,
    4: <Finalization />,
  };
  return <div className="container">{resetPasswordStepEnum[step]}</div>;
};
export default ResetPassword;
