import React from "react";
import { ProductProvider } from "../context/ProductContext";
import { CartProvider } from "../context/CartContext";
import { AuthProvider } from "../context/AuthContext";
import { MyClientsProvider } from "../context/MyClientsContext";
import { MyAccountProvider } from "../context/MyAccountContext";

const ContextProvider = ({ children }) => {
  return (
    <AuthProvider>
      <MyAccountProvider>
        <MyClientsProvider>
          <CartProvider>
            <ProductProvider>{children}</ProductProvider>
          </CartProvider>
        </MyClientsProvider>
      </MyAccountProvider>
    </AuthProvider>
  );
};
export default ContextProvider;
