import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useHttp } from "../../hooks/useHttp";
import toast from "react-hot-toast";

const GetUserCode = ({ stepHandler, emailHandler }) => {
  const [email, setEmail] = useState("");
  const { sendRequest } = useHttp();

  const getToken = async () => {
    const responseData = await sendRequest(
      "https://matrixterra.com.br/ws/customer/forgotPassword/send-code-password-forgot.php",
      "POST",
      JSON.stringify({
        email: email,
      }),
      {
        "Content-Type": "application/json",
      }
    );
    if (responseData.success) {
      emailHandler(email);
      stepHandler("2");
      toast.success(responseData.message);
    } else {
      toast.error(responseData.message);
      //   throw new Error(responseData.message);
    }
  };

  const submitHandler = () => {
    getToken();
  };
  const emailValueHandler = (e) => {
    setEmail(e.target.value);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1 className="form-text-home text-center">Esqueceu sua senha?</h1>
      <p className="text-center mb-50">
        Insira seu e-mail cadastrado para receber um código de recuperação de
        senha.
      </p>
      <input
        value={email}
        className="input-item input-item-email px-3 "
        style={{ width: "100%", maxWidth: "469px" }}
        placeholder="E-mail da sua conta cadastrada*"
        onChange={emailValueHandler}
      />
      <div className="btn-wrapper mt-0">
        <button
          className="btn theme-btn-1 btn-effect-1 text-uppercase"
          type="submit"
          onClick={submitHandler}
          disabled={email === ""}
          style={{
            maxWidth: "100%",
            width: "469px",
            marginTop: "30px",
            marginBottom: "30px",
          }}
        >
          Enviar código de recuperação para e-mail
        </button>
      </div>
      <Link
        to="/authentication"
        style={{
          marginBottom: "100px",
        }}
      >
        Não quero recuperar minha senha agora
      </Link>
    </div>
  );
};
export default GetUserCode;
