import React, { createContext, useState, useCallback, useEffect } from "react";
import { useHttp } from "../hooks/useHttp";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [assistedPurchaseState, setAssistedPurchaseState] = useState(null);
  const { sendRequest } = useHttp();

  const loadUserInfo = useCallback(
    async (uid) => {
      const responseData = await sendRequest(
        "https://matrixterra.com.br/ws/customer/get-user-important-info.php",
        "POST",
        JSON.stringify({
          user_id: parseInt(uid),
        }),
        {
          "Content-Type": "application/json",
          "Access-Control-Request-Method": "application/postscript",
        }
      );
      if (responseData.success) {
        setUserInfo(responseData.content);
      } else {
        throw new Error(responseData.error);
      }
    },
    [sendRequest]
  );
  useEffect(() => {
    if (user) {
      loadUserInfo(user.uid);
    }
  }, [loadUserInfo, user]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: isLoggedIn,
        setIsLoggedIn: setIsLoggedIn,
        user: user,
        setUser: setUser,
        loadUserInfo,
        userInfo,
        setAssistedPurchaseState,
        assistedPurchaseState,
        setUserInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
