import React from 'react';

function AboutV2() {

	let publicUrl = process.env.PUBLIC_URL + '/'

	return <div id='about-us' className="ltn__about-us-area pt-120 pb-90 ">
		<div className="container">
			<div className="row">
				<div className="col-lg-6 align-self-center">
					<div className="about-us-img-wrap about-img-right">
						<img src={publicUrl + "assets/img/others/9.png"} alt="About Us" />
					</div>
				</div>
				<div className="col-lg-6 align-self-center">
					<div className="about-us-info-wrap">
						<div className="section-title-area ltn__section-title-2--- mb-30">
							<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Sobre nós</h6>
							<h1 className="section-title form-text-home">Grupo Terra, gente feliz fazendo bons negócios</h1>
							<p>
								Formada por profissionais com mais de 30 anos de experiência, o Grupo Terra surgiu como uma alternativa confiável para fornecimento de miudezas, químicos e ferramentas, para oficinas mecânicas pesadas autorizadas ou independentes.
								<br></br>
								<br></br>
								Não abrindo mão da qualidade, variedade e geração de lucro a seus clientes principalmente, a empresa norteia-se por valores cristãos no relacionamento social com clientes e colaboradores. Atuamos em todos os níveis da cadeia automotiva: Fabricação, importação, exportação, distribuição de produtos para a mecânica pesada.
								<br></br>
								<br></br>
								O ser humano é quem consome e necessita de cuidados, por isso valorizamos e preferimos o trabalho humano em todas as etapas de todos os processos, mesmo que isto custe mais caro, usando as máquinas sempre que necessário em benefício das pessoas envolvidas.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
}

export default AboutV2