import React, { useCallback } from "react";
import useProduct from "../../../hooks/useProduct";

const ProductFilterItem = ({ id, name }) => {
  const { setCategories, setPage, categories } = useProduct();

  const isChecked = useCallback(
    () => categories.find((e) => e === id),
    [categories, id]
  );

  const handleArrayFilters = (event) => {
    const { checked } = event.target;
    if (checked) {
      setCategories((prev) => [...prev, id]);
      setPage(1);
    } else {
      setCategories((prev) => prev.filter((e) => e !== id));
      setPage(1);
    }
  };

  return (
    <div key={name} className="row">
      <div className="col-2">
        <input
          id={id}
          type="checkbox"
          checked={isChecked()}
          onClick={handleArrayFilters}
        />
      </div>
      <div className="col-6">
        <p>
          <label htmlFor={id}>{name.toUpperCase()}</label>
        </p>
      </div>
    </div>
  );
};
export default ProductFilterItem;
