import React, { useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import useProduct from "../../hooks/useProduct";
import { hasImage } from "../Products/components/ProductItem";
import { PulseLoader } from "react-spinners";
import { formatBRL } from "../../util/formatPriceBRL";
import useCart from "../../hooks/useCart";
import Slider from "react-slick";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, borderRadius: "20px", background: "grey" }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, borderRadius: "20px", background: "grey" }}
      onClick={onClick}
    />
  );
}

const ProductDetail = () => {
  const params = useParams();
  const [quantity, setQuantity] = useState(1);
  const { products, productFilters } = useProduct();
  const { dispatch } = useCart();

  const settings = {
    className: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "row",
    },
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  const quantityHandler = (e) => {
    const { value } = e.target;
    if (value > 0 && value <= 9999 && !isNaN(value)) {
      setQuantity(e.target.value);
    } else {
      setQuantity(1);
    }
  };

  const cartHandler = useCallback(
    (obj) => {
      dispatch({
        type: "ADD",
        obj,
        quantity,
      });
    },
    [dispatch, quantity]
  );

  const product = () => {
    if (params.id && products) {
      return products.find((e) => e.id === params.id);
    }
    return null;
  };
  const productCategory = () => {
    if (product() && productFilters.length !== 0) {
      const value = productFilters.find(
        (e) => e.id === parseInt(product().category)
      );
      return value?.name;
    }
    return null;
  };

  const addToCart = () => {
    const obj = {
      ...product(),
      quantity: parseInt(quantity),
    };
    // console.log(obj);
    cartHandler(obj);
  };
  return (
    <div className="container" style={{ marginBottom: '100px' }}>
      {!products ? (
        <div
          className="container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "600px",
          }}
        >
          <PulseLoader color="#ec1f25" />
        </div>
      ) : (
        <>
          <div className="container title-2">
            <h2 className="ltn__primary-color">{product()?.product_name}</h2>
            <h4 className="ltn__primary-color">{product()?.product_id}</h4>
          </div>
          <div className="row row-cols-lg-2 row-cols-sm-1">
            {product().images_details.length === 0 ? (
              <div
                style={{
                  marginBottom: "100px",
                  paddingLeft: "50px",
                  paddingRight: "50px",
                  margingBottom: "100px",
                }}
              >
                <img
                  src={hasImage(product()?.image)}
                  alt="produto"
                  style={{
                    border: "4px solid #F2F6F7",
                    borderRadius: "20px",
                    height: "500px",
                  }}
                />
              </div>
            ) : (
              <div
                style={{
                  marginBottom: "100px",
                  paddingLeft: "50px",
                  paddingRight: "50px",
                  margingBottom: "100px",
                }}
              >
                <Slider {...settings}>
                  {product().images_details.map((e) => (
                    <div key={e.url_image}>
                      <img
                        src={hasImage(e.url_image)}
                        alt="produto"
                        style={{
                          border: "4px solid #F2F6F7",
                          borderRadius: "20px",
                          height: "500px",
                        }}
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            )}
            <div className="col-6">
              <div
                style={{
                  background: "#F2F6F7",
                  height: "500px",
                  padding: "70px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  overflow: "auto",
                  whiteSpace: "pre-wrap",
                }}
              >
                <h4>{`Categoria  ${productCategory()}`}</h4>
                <p>{product().description ? product().description : <i>==== Produto ainda sem descrição ====</i>}</p>

                {/* <Link to="/cart">
                  <button
                    className="btn-small theme-btn-1 btn-effect-1 w-100 "
                    type="submit"
                    onClick={addToCart}
                  >
                    <i class="fa-solid fa-cart-shopping"></i>
                    Adicionar ao carrinho
                  </button>
                </Link> */}
              </div>
            </div>
          </div>
          <div style={{ marginBottom: "20px", marginTop: "20px" }}>
            <div>
              <h3 className="ltn__secondary-color">Especificações</h3>
            </div>
            <table style={{ borderCollapse: "collapse", width: "100%" }}>
              <thead>
                <tr style={{ border: "none" }}>
                  <td
                    className="ltn__primary-color"
                    style={{ paddingTop: "10px", paddingBottom: "10px" }}
                  >
                    <b>Código do produto</b>
                  </td>
                  <td
                    className="ltn__primary-color"
                    style={{ paddingX: "10px" }}
                  >
                    <b>Valor</b>
                  </td>
                  <td
                    className="ltn__primary-color"
                    style={{ paddingX: "10px" }}
                  >
                    <b>Quantidade</b>
                  </td>
                  <td
                    className="ltn__primary-color"
                    style={{ paddingX: "10px" }}
                  >
                    <b>Valor Total</b>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr style={{ textAlign: "start", background: "#F2F6F7" }}>
                  <td style={{ padding: "10px" }}>{product()?.product_id}</td>
                  <td style={{ padding: "10px" }}>
                    {formatBRL(product()?.price)}
                  </td>
                  <td style={{ textAlign: "start" }}>
                    <input
                      type="number"
                      min={1}
                      max={9999}
                      value={quantity}
                      onChange={quantityHandler}
                      style={{
                        border: "none",
                        width: "80px",
                        paddingTop: "2px",
                        padding: "6px",
                        height: "45.6px",
                      }}
                    />
                  </td>
                  <td style={{ textAlign: "start" }}>
                    {formatBRL(product()?.price * quantity)}
                  </td>
                  <td
                    style={{
                      textAlign: "end",
                      padding: "10px",
                    }}
                  >
                    {/* <Link to="/cart"> */}
                    <button
                      className="btn-small theme-btn-1 btn-effect-1 "
                      type="submit"
                      onClick={addToCart}
                    >
                      <i class="fa-solid fa-cart-shopping"></i>
                      Adicionar ao carrinho
                    </button>
                    {/* </Link> */}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};
export default ProductDetail;
