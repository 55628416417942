import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./OrderHistory.module.css";
import KeywordFilter from "../KeywordFilter";
import OrderFilterOrderHistory from "../OrderFilter";
import useMyAccount from "../../../../hooks/useMyAccount";

export const OrderHistory = () => {
  const [keyword, setKeyword] = useState("");
  const [orderFilter, setOrderFilter] = useState("ASC");
  const [statusFilter, setStatusFilter] = useState("0");
  const handleFilterChange = (event) => {
    setKeyword(event.target.value);
  };
  const handleOrderFilterChange = (event) => {
    setOrderFilter(event.target.value);
  };
  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const { orderHistory, setOrderDetailId } = useMyAccount();

  const loadOrderDetailHandler = (id) => {
    setOrderDetailId(id);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <KeywordFilter keyword={keyword} onFilterChange={handleFilterChange} />
      <OrderFilterOrderHistory
        keyword={keyword}
        onOrderChange={handleOrderFilterChange}
        onStatusFilter={handleStatusFilterChange}
      />
      <div className="ltn__my-properties-table table-responsive col-12">
        <table className="table">
          <thead
            style={{
              borderRight: "hidden",
              borderLeft: "hidden",
              borderTop: "hidden",
            }}
          >
            <tr>
              <th scope="col">Últimos pedidos</th>
              <th
                scope="col"
                className={styles.columnCenter}
                style={{ textAlign: "center" }}
              >
                Data
              </th>
              <th
                scope="col"
                className={styles.columnCenter}
                style={{ textAlign: "center" }}
              >
                Visualizar
              </th>
            </tr>
          </thead>
          <tbody
            style={{
              borderRight: "hidden",
              borderLeft: "hidden",
              fontSize: "14px",
            }}
          >
            {orderHistory
              .sort((a, b) => {
                if (orderFilter === "ASC") {
                  return parseInt(a?.id_order) - parseInt(b?.id_order);
                } else {
                  return parseInt(b?.id_order) - parseInt(a?.id_order);
                }
              })
              // eslint-disable-next-line array-callback-return
              .filter((order) => {
                if (statusFilter === "0") {
                  return order;
                } else {
                  return order.status_order === statusFilter;
                }
              })
              .filter((order) => {
                return (
                  order?.buyer_name.includes(keyword) ||
                  order?.id_order.includes(keyword)
                );
              })
              .map((order) => (
                <tr key={order?.id} className="mb-5">
                  <td>
                    <h6 className="mb-10 go-top">
                      <Link>{`Pedido N ${order?.id_order}`}</Link>
                    </h6>
                    <p className="mb-2">{`Comprador: ${order?.buyer_name}`}</p>
                    <strong>
                      {order?.status_order === "1" && (
                        <>
                          <i
                            className="fa-solid fa-circle"
                            style={{ color: "#FB5A00", paddingRight: "8px" }}
                          ></i>
                          Aguardando autorização
                        </>
                      )}
                      {order?.status_order === "2" && (
                        <>
                          <i
                            className="fa-solid fa-circle"
                            style={{ color: "#38B2F6", paddingRight: "8px" }}
                          ></i>
                          Pedido em separação
                        </>
                      )}
                      {order?.status_order === "3" && (
                        <>
                          <i
                            className="fa-solid fa-circle"
                            style={{ color: "#F9D100", paddingRight: "8px" }}
                          ></i>
                          Pedido em aberto
                        </>
                      )}
                      {order?.status_order === "4" && (
                        <>
                          <i
                            className="fa-solid fa-circle"
                            style={{ color: "#77C720", paddingRight: "8px" }}
                          ></i>
                          Pedido efetuado
                        </>
                      )}
                    </strong>
                  </td>
                  <td style={{ textAlign: "center" }}>{order?.order_date}</td>
                  <td style={{ textAlign: "center" }}>
                    <Link to="/detailsOrderHistory">
                      <button
                        style={{ padding: "16px", width: "max-content" }}
                        className="theme-btn-1 btn btn-block"
                        onClick={() => loadOrderDetailHandler(order.id_order)}
                      >
                        <i className="icon-search for-search-show"></i>
                        Ver detalhe da compra
                      </button>
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
