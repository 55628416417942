import React from "react";
import { useAuth } from "../../../hooks/useAuth";
import useProduct from "../../../hooks/useProduct";

const productOrderEnum = {
  "Alphabetic-ASC": {
    type: "Alphabetic",
    order: "ASC",
  },
  "Alphabetic-DESC": {
    type: "Alphabetic",
    order: "DESC",
  },
  "Price-ASC": {
    type: "Price",
    order: "ASC",
  },
  "Price-DESC": {
    type: "Price",
    order: "DESC",
  },
};

const ProductListHeader = () => {
  const { isLoggedIn } = useAuth();
  const { setProductOrder, productOrder } = useProduct();

  const handleOrder = (e) => {
    const { value } = e.target;
    setProductOrder(productOrderEnum[value]);
  };

  return (
    <div className="row flex-md-row">
      <div className="col-lg-3 col-xs-12">
        <h2 className="form-text-home text-left mb-5 ">Filtrar por</h2>
      </div>
      {isLoggedIn && (
        <div className="col-lg-9">
          <div className="row justify-content-start align-content-center">
            <div className="short-by text-center d-flex align-items-center col-lg-2 col-xs-12 mb-2">
              <select
                onChange={handleOrder}
                value={`${productOrder.type}-${productOrder.order}`}
                className="nice-select"
              >
                <option value="Alphabetic-ASC">Ordem Alfabética (A-Z)</option>
                <option value="Alphabetic-DESC">Ordem Alfabética (Z-A)</option>
                <option value="Price-ASC">Ordenar por preço Crescente</option>
                <option value="Price-DESC">
                  Ordenar por preço Decrescente
                </option>
              </select>
            </div>
          </div>
          <i class="fa-solid fa-grid-2 ltn__secondary-color"></i>
        </div>
      )}
    </div>
  );
};
export default ProductListHeader;
