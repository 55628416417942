import React from "react";
import DetailsOrderHistory from "./DetailsOrderHistory";

const MyAccountDetailsOrderHistory = () => {
  return (
    <div className="tab-pane fade active show" id="tab_11">
      <div className="ltn__myaccount-tab-content-inner">
        <DetailsOrderHistory />
      </div>
    </div>
  );
};
export default MyAccountDetailsOrderHistory;
