import React from "react";
import { PaymentDelivery } from "./PaymentDelivery";

const MyAccountPaymentDelivery = () => {
  return (
    <div className="tab-pane fade active show" id="tab_5">
      <div className="ltn__myaccount-tab-content-inner">
        <PaymentDelivery />
      </div>
    </div>
  );
};
export default MyAccountPaymentDelivery;
