import React, { useState, useEffect } from "react";
import KeywordFilter from "../MyAccount/components/KeywordFilter";
import OrderFilter from "../MyAccount/components/OrderFilter";
import { Link } from "react-router-dom";
import styles from "../MyClients/myclients.module.css";
import { useParams } from "react-router-dom";
import useMyClients from "../../hooks/useMyClients";
import { hasImage } from "../Products/components/ProductItem";
import { useAuth } from "../../hooks/useAuth";
import useCart from "../../hooks/useCart";

const MyClientsCollaborators = () => {
  const { companyCollaborators, loadCollaborators } = useMyClients();
  const { user, setAssistedPurchaseState } = useAuth();
  const { loadClientCartId } = useCart();
  const params = useParams();

  useEffect(() => {
    loadCollaborators(params.myClientsCollabId);
  }, [loadCollaborators, params.myClientsCollabId]);

  const assistedPurchaseHandler = (client) => {
    setAssistedPurchaseState({
      terraAssistent: user.uid,
      clientUserId: client.uid,
      clientName: client.full_name,
      clientCompany: client.company_name,
      clientFrete: client.frete,
      client_cashback_amount: client.cashback_amount,
      client_cashback_percentage: client.cashback_percentage,
    });
    loadClientCartId(client.uid);
  };

  const iconStyle = { color: "#EC1F25" };
  const buttonContainerStyle = { display: "flex", gap: "10px" };

  return (
    <>
      <div className="ltn__my-properties-table table-responsive container my-5">
        <>
          <h4 className="title-2">Colaboradores do meu Cliente </h4>
          <table className="table">
            <tbody
              style={{
                borderRight: "hidden",
                borderLeft: "hidden",
                fontSize: "14px",
              }}
            >
              {companyCollaborators.length === 0 ? (
                <h1>Loading...</h1>
              ) : (
                companyCollaborators.map((e) => (
                  <tr key={e.id} className="mb-5">
                    <td
                      style={{
                        width: "180px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={hasImage(e.image)}
                        alt="Author"
                        style={{
                          width: "124px",
                          height: "124px",
                          borderRadius: '8px'
                        }}
                      />
                    </td>
                    <td>
                      <h2 className={styles.nameCollaborator}>{e.full_name}</h2>
                      <p className={styles.description}>
                        {e.is_master_user ? "Administrador em" : "Vendedor em "}
                        {/* {e.} */}
                        <span style={{ color: "#EC1F25" }}> Sol Soluções</span>
                      </p>

                      <div className="footer-address">
                        <div
                          className="button-container"
                          style={buttonContainerStyle}
                        >
                          {
                            e.cellphone && <a
                              className={styles.btn}
                              href={`https://api.whatsapp.com/send?phone=${e.cellphone}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="fab fa-whatsapp" style={iconStyle} />
                            </a>
                          }
                          {
                            e.email && <a
                              className={styles.btn}
                              href={`mailto:${e.email}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="far fa-envelope" style={iconStyle} />
                            </a>
                          }
                        </div>
                      </div>
                    </td>
                    <td style={{ textAlign: "end" }}>
                      <Link
                        to="/products"
                        onClick={() => {
                          assistedPurchaseHandler(e);
                        }}
                      >
                        <button
                          className="theme-btn-1 btn btn-block"
                          type="submit"
                        >
                          <i className="fas fa-search" />
                          Comprar para esse Colaborador
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </>
      </div>
    </>
  );
};
export default MyClientsCollaborators;
