import React from "react";

const Input = ({
  errors,
  register,
  className,
  fieldName,
  placeholder,
  type,
  textarea,
  rules,
  disabled,
  rest,
}) => {
  return (
    <div className={className}>
      {textarea ? (
        <textarea
          {...register(`${fieldName}`)}
          type={type}
          name={fieldName}
          placeholder={placeholder}
          {...rest}
        />
      ) : (
        <input
          {...register(`${fieldName}`)}
          type={type}
          name={fieldName}
          placeholder={placeholder}
          disabled={disabled}
          {...rest}
        />
      )}
      {errors[`${fieldName}`] && (
        <p className="ltn__secondary-color">{errors[`${fieldName}`].message}</p>
      )}
    </div>
  );
};
export default Input;
