import React, { createContext, useState, useEffect, useCallback } from "react";
import { useHttp } from "../hooks/useHttp";

export const MyClientsContext = createContext({});

export const MyClientsProvider = ({ children }) => {
  const { sendRequest } = useHttp();
  const [companyClients, setCompanyClients] = useState([]);
  const [companyCollaborators, setCompanyCollaborators] = useState([]);

  const loadCollaborators = useCallback(async (companyId) => {
    const responseData = await sendRequest(
      "https://matrixterra.com.br/ws/customer/all_members_of_company.php",
      "POST",
      JSON.stringify({
        company_id: companyId,
      }),
      {
        "Content-Type": "application/json",
      }
    );
    if (responseData.success) {
      const { users } = responseData;
      setCompanyCollaborators(users);
    } else {
      throw new Error(responseData.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loadCompanys = async () => {
      const responseData = await sendRequest(
        "https://matrixterra.com.br/ws/customer/all_companies.php",
        "GET",
        null,
        {
          "Content-Type": "application/json",
        }
      );
      if (responseData.success) {
        setCompanyClients(responseData.companies);
      } else {
        throw new Error(responseData.error);
      }
    };

    loadCompanys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MyClientsContext.Provider
      value={{
        companyClients,
        loadCollaborators,
        companyCollaborators,
      }}
    >
      {children}
    </MyClientsContext.Provider>
  );
};
