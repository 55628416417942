import React, {
  createContext,
  useCallback,
  useReducer,
  useEffect,
  useState,
} from "react";
import { useHttp } from "../hooks/useHttp";
import toast from "react-hot-toast";
import { useAuth } from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const cartReducer = (state, action) => {
  switch (action.type) {
    case "CLEAR": {
      return {
        cartItems: [],
      };
    }
    case "LOAD": {
      return {
        cartItems: action.cartArray,
      };
    }
    case "ADD": {
      const { obj, quantity } = action;
      if (state.cartItems.find((e) => e.product_id === action.obj.product_id)) {
        const newArray = state.cartItems.map((e) => {
          if (e.product_id === obj.product_id) {
            if (e.is_deleted === true) {
              return {
                ...e,
                is_deleted: false,
                quantity: parseInt(quantity),
              };
            }
            return {
              ...e,
              quantity: parseInt(e.quantity) + parseInt(quantity),
            };
          } else {
            return e;
          }
        });
        return {
          cartItems: newArray,
        };
      } else {
        const newObj = {
          ...obj,
          quantity: parseInt(quantity),
        };
        return {
          cartItems: [...state.cartItems, newObj],
        };
      }
    }
    case "DELETE": {
      const { obj } = action;
      let newArray;
      newArray = state.cartItems.map((e) => {
        if (e.product_id === obj.product_id) {
          return {
            ...e,
            is_deleted: true,
            quantity: 0,
          };
        } else {
          return e;
        }
      });
      return {
        cartItems: newArray,
      };
    }
    case "CHANGE_QUANTITY": {
      const { obj, quantity } = action;
      let newArray;
      if (parseInt(quantity) === 0) {
        newArray = state.cartItems.filter(
          (e) => e.product_id !== obj.product_id
        );
      } else {
        newArray = state.cartItems.map((e) => {
          if (e.product_id === obj.product_id) {
            return {
              ...e,
              quantity: parseInt(quantity),
            };
          } else {
            return e;
          }
        });
      }
      return {
        cartItems: newArray,
      };
    }
    default:
      return state;
  }
};

export const CartContext = createContext({});
export const CartProvider = ({ children }) => {
  const [cartState, dispatch] = useReducer(cartReducer, {
    cartItems: [],
  });
  const [cartId, setCartId] = useState(null);
  const [cashback, setCashback] = useState(false);
  const [clientCartId, setClientCartId] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectPaymentMethod, setSelectedPaymentMethod] = useState("");

  const { sendRequest } = useHttp();
  const { assistedPurchaseState, user, setAssistedPurchaseState, userInfo } =
    useAuth();
  const storedData = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();

  const validCart = useCallback(
    () => cartState.cartItems.filter((e) => e.is_deleted === false),
    [cartState.cartItems]
  );
  const totalValue = useCallback(() => {
    const totalValue = validCart().reduce((value, next) => {
      return value + next.quantity * next.price;
    }, 0);
    return totalValue;
  }, [validCart]);

  const orderFinalValue = useCallback(() => {
    return (
      totalValue() +
      userInfo?.frete -
      (cashback ? userInfo?.cashback_amount : 0)
    );
  }, [cashback, totalValue, userInfo?.cashback_amount, userInfo?.frete]);

  const assistedOrderFinalValue = useCallback(() => {
    return (
      totalValue() +
      assistedPurchaseState?.clientFrete -
      (cashback ? assistedPurchaseState?.client_cashback_amount : 0)
    );
  }, [
    assistedPurchaseState?.clientFrete,
    assistedPurchaseState?.client_cashback_amount,
    cashback,
    totalValue,
  ]);

  const cashbackValue = useCallback(
    () => (userInfo?.cashback_percentage * totalValue()) / 100,
    [totalValue, userInfo?.cashback_percentage]
  );
  const assistedCashbackValue = useCallback(
    () =>
      (assistedPurchaseState?.client_cashback_percentage * totalValue()) / 100,
    [assistedPurchaseState?.client_cashback_percentage, totalValue]
  );

  useEffect(() => {
    const updateCart = async (userId) => {
      const responseData = await sendRequest(
        "https://matrixterra.com.br/ws/customer/updateCart.php",
        "POST",
        JSON.stringify({ uid: userId, cart: cartState.cartItems }),
        {
          "Content-Type": "application/json",
        }
      );
      if (responseData.error) {
        throw new Error(responseData.error);
      }
    };
    if (storedData?.userId) {
      if (assistedPurchaseState) {
        updateCart(assistedPurchaseState.clientUserId);
      }
      updateCart(storedData.userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartState.cartItems]);
  useEffect(() => {
    const getPaymentMethods = async () => {
      const responseData = await sendRequest(
        "https://matrixterra.com.br/ws/customer/get_payment_methods.php",
        "GET",
        null,
        {
          "Content-Type": "application/json",
        }
      );
      if (responseData.success) {
        setPaymentMethods(responseData.payment_methods);
      }
      if (responseData.error) {
        throw new Error(responseData.error);
      }
    };
    getPaymentMethods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadCart = useCallback(async (userId) => {
    const responseData = await sendRequest(
      "https://matrixterra.com.br/ws/customer/user-cart.php",
      "POST",
      JSON.stringify({ uid: `${userId}` }),
      {
        "Content-Type": "application/json",
      }
    );
    if (responseData.success) {
      setCartId(responseData.order_id);
      dispatch({
        type: "LOAD",
        cartArray: responseData.cart,
      });
    } else {
      throw new Error(responseData.error);
    }
  }, []);

  const finalizeCart = async () => {
    const responseData = await sendRequest(
      "https://matrixterra.com.br/ws/customer/finalizeCart.php",
      "POST",
      assistedPurchaseState
        ? JSON.stringify({
            payment_method_id: selectPaymentMethod.id,
            uuid: assistedPurchaseState.clientUserId,
            cart_id: parseInt(clientCartId),
            id_user_terra: assistedPurchaseState.terraAssistent,
            cart_id_user_terra: parseInt(cartId),
            use_cashback: cashback,
            company_id: assistedPurchaseState.clientCompany,
            cashback_value: assistedCashbackValue(),
          })
        : JSON.stringify({
            payment_method_id: selectPaymentMethod.id,
            uuid: parseInt(user.uid),
            cart_id: parseInt(cartId),
            id_user_terra: null,
            use_cashback: cashback,
            company_id: userInfo.company_id,
            cashback_value: cashbackValue(),
          }),
      {
        "Content-Type": "application/json",
      }
    );
    if (responseData.success) {
      if (assistedPurchaseState) {
        toast.success(
          `Carrinho do cliente ${assistedPurchaseState.clientName} foi finalizado com sucesso!`,
          {
            position: "bottom-left",

            duration: 3000,
          }
        );
      }
      toast.success("Compra realizada com sucesso!");
      dispatch({ type: "CLEAR" });
    } else {
      toast.error("Algo deu errado!");
      throw new Error(responseData.error);
    }
    setAssistedPurchaseState(null);
    // loadCart(user.uid);
    dispatch({ type: "CLEAR" });
    setCashback(false);
    navigate("/finalizationCart");
  };

  const loadClientCartId = useCallback(async (ClientUid) => {
    const responseData = await sendRequest(
      "https://matrixterra.com.br/ws/customer/get-user-cart-id.php",
      "POST",
      JSON.stringify({
        uid: parseInt(ClientUid),
      }),
      {
        "Content-Type": "application/json",
      }
    );
    if (responseData.success) {
      setClientCartId(responseData.id_cart);
    } else {
      throw new Error(responseData.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadCartId = useCallback(async (uid) => {
    const responseData = await sendRequest(
      "https://matrixterra.com.br/ws/customer/get-user-cart-id.php",
      "POST",
      JSON.stringify({
        uid: parseInt(uid),
      }),
      {
        "Content-Type": "application/json",
      }
    );
    
    if (responseData.success) {
      setCartId(responseData.id_cart);
    } else {
      console.log(responseData.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      loadCartId(user.uid);
      loadCart(user.uid);
    }
  }, [loadCart, loadCartId, user]);

  return (
    <CartContext.Provider
      value={{
        cart: validCart(),
        cashback: cashback,
        paymentMethods: paymentMethods,
        paymentMethod: selectPaymentMethod,
        setPaymentMethod: setSelectedPaymentMethod,
        totalValue,
        dispatch,
        loadCart,
        finalizeCart,
        loadCartId,
        setClientCartId,
        setCartId,
        loadClientCartId,
        setCashback,
        orderFinalValue: assistedPurchaseState
          ? assistedOrderFinalValue
          : orderFinalValue,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
