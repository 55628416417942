import React from "react";
import { CollaboratorDetails } from "./CollaboratorDetails";

const MyAccountCollabDetails = () => {
  return (
    <div className="tab-pane fade active show" id="tab_9">
      <div className="ltn__myaccount-tab-content-inner">
        <CollaboratorDetails />
      </div>
    </div>
  );
};
export default MyAccountCollabDetails;
