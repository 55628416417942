import React, { useState } from "react";

const ChangePasswordForm = () => {
  const [changePasswordSuccess, setChangePasswordSuccess] = useState(false);

  return (
    <div className="ltn__myaccount-tab-content-inner">
      {!changePasswordSuccess ? (
        <div className="account-login-inner">
          <form action="#" className="ltn__form-box contact-form-box">
            <h5 className="title-2 mb-30">Alterar Senha</h5>
            <input
              type="password"
              name="password"
              placeholder="Senha atual*"
              className="mb-15"
            />
            <input
              type="password"
              name="password"
              placeholder="Senha nova*"
              className="mb-15"
            />
            <input
              type="password"
              name="password"
              placeholder="Confirmar senha nova*"
              className="mb-15"
            />
            <div className="btn-wrapper mt-0">
              <button className="theme-btn-1 btn btn-block" type="submit">
                Salvar mudança
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className="account-login-inner">
          <h5 className="title-2 mb-30">Sua senha foi alterada!</h5>
        </div>
      )}
    </div>
  );
};

export default ChangePasswordForm;
