import React from "react";
import { useLocation } from "react-router";
import userImage from "../../../../data/user_icon.jpg";

export const CollaboratorDetails = () => {
  const iconStyle = { color: "#EC1F25" };
  const { state } = useLocation();
  const collaboratorDetails = state?.collaborator;

  const handleEmailClick = async () => {
    window.location.href = `mailto:${collaboratorDetails?.email}`;
  };
  const handlePhoneClick = async () => {
    const phoneLink = `tel:${collaboratorDetails?.cellphone}`;
    window.location.href = phoneLink;
  };

  return (
    <div>
      <div className="ltn-author-introducing clearfix">
        <div className="author-img">
          <img
            style={{ width: "180px", height: "180px", objectFit: "cover" }}
            src={collaboratorDetails.image ? `data:image/png;base64,${collaboratorDetails?.image}` : userImage}
            alt="Collaborator"
          />
        </div>
        <div className="author-info">
          <h6>Vendedor da Terra</h6>
          <h2 style={{ color: "#071C1F" }}>{collaboratorDetails?.full_name}</h2>
          <div className="footer-address">
            <ul>
              <li>
                <div className="footer-address-icon">
                  <i className="fab fa-whatsapp" style={iconStyle} />
                </div>
                <div className="footer-address-info">
                  <p>
                    <a href={`https://wa.me/${collaboratorDetails?.whatsapp}`} target="_blank" rel="noreferrer">
                      {collaboratorDetails?.whatsapp}
                    </a>
                  </p>
                </div>
              </li>
              <li>
                <div className="footer-address-icon">
                  <i className="far fa-envelope" style={iconStyle} />
                </div>
                <div className="footer-address-info">
                  <p>
                    <a href="#" onClick={handleEmailClick}>
                      {collaboratorDetails?.email}
                    </a>
                  </p>
                </div>
              </li>
              <li>
                <div className="footer-address-icon">
                  <i className="fas fa-briefcase" style={iconStyle} />
                </div>
                <div className="footer-address-info">
                  <p>{collaboratorDetails?.company_name}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </p> */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="footer-address">
          <ul>
            <li>
              <div className="footer-address-icon">
                <i className="far fa-user" style={iconStyle} />
              </div>
              <div className="footer-address-info">
                <p>
                  <strong>Nome: </strong>
                  {collaboratorDetails?.full_name}
                </p>
              </div>
            </li>
            <li>
              <div className="footer-address-icon">
                <i className="fab fa-whatsapp" style={iconStyle} />
              </div>
              <div className="footer-address-info">
                <p>
                  <strong>Whatsapp: </strong>
                  <a href={`https://wa.me/${collaboratorDetails?.whatsapp}`} target="_blank" rel="noreferrer">
                    {collaboratorDetails?.whatsapp}
                  </a>
                </p>
              </div>
            </li>
            <li>
              <div className="footer-address-icon">
                <i className="fas fa-phone fa-rotate-90" style={iconStyle} />
              </div>
              <div className="footer-address-info">
                <p>
                  <strong>Telefone: </strong>
                  <a href="#" onClick={handlePhoneClick}>
                    {collaboratorDetails?.cellphone}
                  </a>
                </p>
              </div>
            </li>
          </ul>
        </div>
        <div className="footer-address">
          <ul>
            <li>
              <div className="footer-address-icon">
                <i className="far fa-envelope" style={iconStyle} />
              </div>
              <div className="footer-address-info">
                <p>
                  <strong>Email: </strong>
                  <a href="#" onClick={handleEmailClick}>
                    {collaboratorDetails?.email}
                  </a>
                </p>
              </div>
            </li>
            <li>
              <div className="footer-address-icon">
                <i className="far fa-building" style={iconStyle} />
              </div>
              <div className="footer-address-info">
                <div className="footer-address-info">
                  <p>
                    <strong>Empresa: </strong>
                    <span
                      style={{
                        fontWeight: 700,
                        color: "var(--ltn__secondary-color)",
                      }}
                    >
                      {collaboratorDetails?.company_name}
                    </span>
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="footer-address-icon">
                <i className="far fa-id-badge" style={iconStyle} />
              </div>
              <div className="footer-address-info">
                <p>
                  <strong>Função: </strong>
                  {collaboratorDetails?.function}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
