import { useContext } from "react";
import { MyClientsContext } from "../context/MyClientsContext";

const useMyClients = () => {
  const { companyClients, isLoading, loadCollaborators, companyCollaborators } =
    useContext(MyClientsContext);

  return {
    companyClients,
    isLoading,
    loadCollaborators,
    companyCollaborators,
  };
};
export default useMyClients;
