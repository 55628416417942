import React from "react";
import Slider from "react-slick";

function Sponsor() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    arrows: false,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };

  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div className="brands-logo-background ltn__brand-logo-area ltn__brand-logo-1 pt-50 pb-50 plr--9 d-none---">
      <div className="container-fluid">
        <Slider {...settings}>
          <div className="col-lg-12">
            <div className="ltn__brand-logo-item d-flex justify-content-center align-items-center">
              <img
                src={publicUrl + "assets/img/brand-logo/1.png"}
                alt="Brand Logo"
              />
            </div>
          </div>
          {/* <div className="col-lg-12">
            <div className="ltn__brand-logo-item d-flex justify-content-center align-items-center">
              <img
                src={publicUrl + "assets/img/brand-logo/2.png"}
                alt="Brand Logo"
              />
            </div>
          </div> */}
          <div className="col-lg-12">
            <div className="ltn__brand-logo-item d-flex justify-content-center align-items-center">
              <img
                src={publicUrl + "assets/img/brand-logo/3.png"}
                alt="Brand Logo"
              />
            </div>
          </div>
          <div className="col-lg-12">
            <div className="ltn__brand-logo-item d-flex justify-content-center align-items-center">
              <img
                src={publicUrl + "assets/img/brand-logo/4.png"}
                alt="Brand Logo"
              />
            </div>
          </div>
          <div className="col-lg-12">
            <div className="ltn__brand-logo-item d-flex justify-content-center align-items-center">
              <img
                src={publicUrl + "assets/img/brand-logo/5.png"}
                alt="Brand Logo"
              />
            </div>
          </div>
          <div className="col-lg-12">
            <div className="ltn__brand-logo-item d-flex justify-content-center align-items-center">
              <img
                src={publicUrl + "assets/img/brand-logo/6.png"}
                alt="Brand Logo"
              />
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
}

export default Sponsor;
