import React from "react";
import useMyAccount from "../../../../hooks/useMyAccount";

export const ContactSeller = () => {
  const iconStyle = { color: "#EC1F25" };
  const { contactTerra } = useMyAccount();

  return (
    <div className="ltn-author-introducing clearfix">
      <div className="author-img">
        <img
          src={`data:image/png;base64,${contactTerra?.image}`}
          alt="Author"
        />
      </div>
      <div className="author-info">
        <h6>Vendedor da Terra</h6>
        <h2 style={{ color: "#071C1F" }}>{contactTerra?.full_name}</h2>
        <div className="footer-address">
          <ul>
            <li>
              <div className="footer-address-icon">
                <i className="fab fa-whatsapp" style={iconStyle} />
              </div>
              <div className="footer-address-info">
                <p>
                  <a
                    href={`https://wa.me/${contactTerra?.whatsapp}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {contactTerra?.whatsapp}
                  </a>
                </p>
              </div>
            </li>
            <li>
              <div className="footer-address-icon">
                <i className="far fa-envelope" style={iconStyle} />
              </div>
              <div className="footer-address-info">
                <p>
                  <a href={`mailto:${contactTerra?.email}`}>
                    {contactTerra?.email}
                  </a>
                </p>
              </div>
            </li>
            <li>
              <div className="footer-address-icon">
                <i className="fas fa-briefcase" style={iconStyle} />
              </div>
              <div className="footer-address-info">
                <p>Terra</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
