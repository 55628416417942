import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import styles from "./MobileMenu.module.css";
import useMyAccount from "../../hooks/useMyAccount";

const MobileMenu = ({ onWhatsappButton, isOpen, handleCloseButton }) => {
  const { isLoggedIn } = useAuth();
  const { logout } = useAuth();
  const buttonAuth = isLoggedIn ? "/myAccount/main" : "/authentication";
  const { userData } = useMyAccount();

  return (
    <div
      onMouseLeave={handleCloseButton}
      id="ltn__utilize-mobile-menu"
      className={`ltn__utilize ltn__utilize-mobile-menu ${
        isOpen ? "expanded" : ""
      }`}
      style={{
        boxShadow: "0 0 50px 0 rgba(46, 56, 220, 0.2)",
        padding: 0,
      }}
    >
      <div className={styles.title}>
        <button>
          <Link to={buttonAuth}>
            {isLoggedIn ? (
              <>
                <i
                  className="fas fa-user"
                  style={{
                    color: "var(--ltn__secondary-color)",
                    fontSize: "16px",
                    marginRight: "8px",
                  }}
                ></i>
                Olá,{" "}
                {userData && userData.user_name
                  ? userData.user_name.split(" ")[0]
                  : ""}
              </>
            ) : (
              <>
                <i
                  className="fas fa-user"
                  style={{
                    color: "var(--ltn__secondary-color)",
                    fontSize: "16px",
                    marginRight: "8px",
                  }}
                ></i>
                Olá, faça seu login
              </>
            )}
          </Link>
        </button>
        <button onClick={handleCloseButton} className="ltn__utilize-close">
          ×
        </button>
      </div>
      <div style={{ padding: "0 16px " }}>
        <nav>
          <ul style={{ listStyle: "none", color: "#000" }}>
            <li className={styles.text}>
              <i className="fas fa-home"></i>
              <Link to="/">Página Inicial</Link>
            </li>
            <li className={styles.text}>
              <i className="fas fa-shopping-basket"></i>
              <Link to="/products">Produtos</Link>
            </li>
            <li className={styles.text}>
              <i className="fas fa-shopping-cart"></i>
              <Link to="/cart">Carrinho</Link>
            </li>
            {isLoggedIn && (
              <>
                <li
                  style={{ borderBottom: "1px solid #E3E3E3", margin: 0 }}
                ></li>
                <li className={styles.text}>
                  <i className="fas fa-user"></i>
                  <Link to="/myAccount/main">Minha Conta</Link>
                </li>
                <li className={styles.text}>
                  <i className="fas fa-briefcase"></i>
                  <Link to="/myAccount/contact">Meu Contato Terra</Link>
                </li>
                <li className={styles.text}>
                  <i className="fas fa-users"></i>
                  <Link to="/myAccount/collab">Perfis de Colaboradores</Link>
                </li>
                <li className={styles.text}>
                  <i className="fas fa-tasks"></i>
                  <Link to="/myAccount/orderHistory">Histórico de Pedidos</Link>
                </li>
                <li className={styles.text}>
                  <i className="fas fa-money-check-alt"></i>
                  <Link to="/myAccount/paymentDelivery">
                    Pagamento e Entrega
                  </Link>
                </li>
              </>
            )}
            <li className={styles.text}>
              <button
                onClick={onWhatsappButton}
                className={styles.contactButton}
              >
                Entre em Contato
              </button>
            </li>
            <li className={styles.text}>
              {isLoggedIn ? (
                <button onClick={logout}>
                  <span>Sair da conta</span>
                </button>
              ) : (
                <button>
                  <Link to={buttonAuth}>
                    <span>Login</span>
                  </Link>
                </button>
              )}
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default MobileMenu;
