import React from "react";
import Slider from "react-slick";

function Phrases() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    arrows: false,
    autoplaySpeed: 1000,
    cssEase: "linear",
  };

  return (
    <div className="ltn__brand-logo-area ltn__brand-logo-1 pt-25 pb-25 plr--9 d-none---">
      <Slider {...settings}>
        <span className="col-12 col-sm-6 col-md-4 d-flex justify-content-center align-items-center text-center">
          Ferramentas profissionais para resultados profissionais.
        </span>
        <span className="col-12 col-sm-6 col-md-4 d-flex justify-content-center align-items-center text-center">
          Inovação e Tradição em cada peça. Conheça nossa linha de produtos.
        </span>
        <span className="col-12 col-sm-6 col-md-4 d-flex justify-content-center align-items-center text-center">
          Resultados incríveis começam com as ferramentas certas.
        </span>
      </Slider>
    </div>
  );
}

export default Phrases;
