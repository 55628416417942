import React from "react";
import { Link } from "react-router-dom/dist";
import { useAuth } from "../../../hooks/useAuth";
import { formatBRL } from "../../../util/formatPriceBRL";
import { toast } from "react-hot-toast";
export const hasImage = (img) => {
  return img
    ? "data:image/jpeg;base64," + img
    : "https://matrixterra.com.br/data/company_images/logo.jpg";
};

const handleShare = (id) => {
  navigator.clipboard.writeText(
    `https://matrixterra.com.br/product-detail/${id}`
  );
  toast.success("Copiado para área de transferência!", {
    position: "bottom-center",
  });
};

const ProductItem = (obj) => {
  const { isLoggedIn } = useAuth();

  const CartButton = isLoggedIn ? (
    <div className="btn-wrapper mt-2 text-align-center">
      <Link to={`/product-detail/${obj.id}`}>
        <button className="btn-small theme-btn-1 btn-effect-1 w-100 ">
          <i class="fa-solid fa-cart-shopping"></i>
          Ver mais
        </button>
      </Link>
    </div>
  ) : (
    <div className="btn-wrapper mt-2 text-align-center">
      <Link to="/authentication">
        <button
          className="btn-small theme-btn-1 btn-effect-1 w-100 "
          // onClick={() => cartHandler(obj)}
        >
          Faça o login para mais informações
        </button>
      </Link>
    </div>
  );

  // const changeCartItemQuantity = (event, obj) => {
  //   dispatch({
  //     type: "CHANGE_QUANTITY",
  //     obj: obj,
  //     quantity: event.target.value,
  //   });
  // };

  return (
    <>
      <div key={obj.id} className="col-xs-12 col-sm-4 col-md-4 mb-4 h-100">
        <Link to={`/product-detail/${obj.id}`}>
          <div
            className="ltn__form-box  box-shadow white-bg"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {/* <img src={obj.image} alt="produto" /> */}
            <img
              src={hasImage(obj.image)}
              alt="produto"
              style={{
                height: "250px",
              }}
            />
          </div>
        </Link>
        {isLoggedIn && (
          <div className="ltn__form-box detail-box box-shadow white-bg">
            <div className="d-flex align-items-center justify-content-between p-4">
              <Link to={`/product-detail/${obj.id}`}>Ver detalhes</Link>
              <div>
                <i
                  id="share"
                  onClick={() => handleShare(obj.id)}
                  style={{
                    cursor: "pointer",
                  }}
                  class="fa-solid fa-share-nodes ltn__secondary-color"
                ></i>
              </div>
            </div>
          </div>
        )}
        <div className="ltn__form-box detail-box box-shadow py-4 px-2">
          <Link
            to={isLoggedIn ? `/product-detail/${obj.id}` : "/authentication"}
          >
            <h4 className="product-label ltn__primary-color ">
              {obj.product_name}
            </h4>
          </Link>
          {/* {isLoggedIn && (
            <h5 className="ltn__secondary-color px-1">{`${formatBRL(
              obj.price
            )} / un`}</h5>
          )} */}
          {CartButton}
        </div>
      </div>
    </>
  );
};
export default ProductItem;
