import { useContext } from "react";
import { CartContext } from "../context/CartContext";
const useCart = () => {
  const {
    cart,
    totalValue,
    dispatch,
    loadCart,
    finalizeCart,
    loadCartId,
    loadClientCartId,
    setClientCartId,
    cashbackHandler,
    setCartId,
    setCashback,
    orderFinalValue,
    cashback,
    paymentMethod,
    setPaymentMethod,
    paymentMethods,
  } = useContext(CartContext);

  return {
    paymentMethod,
    setPaymentMethod,
    orderFinalValue,
    cashback,
    setCashback,
    cart,
    totalValue,
    cashbackHandler,
    dispatch,
    loadCart,
    finalizeCart,
    loadCartId,
    setCartId,
    paymentMethods,
    setClientCartId,
    loadClientCartId,
  };
};
export default useCart;
