import React from "react";
import { Link } from "react-router-dom";
import { whatsapp_links } from "../../data/contact";

export default function BannerV2() {
  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div
      id="home"
      className="ltn__slider-area ltn__slider-11  ltn__slider-11-slide-item-count-show--- ltn__slider-11-pagination-count-show--- section-bg-1"
    >
      <div className="ltn__slider-11-inner">
        <div className="ltn__slider-11-active">
          <div className="ltn__slide-item-inner">
            <div className="container">
              <div className="row mt-5 mt-md-0">
                <div className="col-md-4 align-self-center">
                  <div className="slide-item-info">
                    <div>
                      <h6 className="slide-sub-title animated ltn__secondary-color">
                        Grupo Terra
                      </h6>
                      <h1 className="slide-title animated ">
                        Fornecendo qualidade e lucro no segmento de mecânica
                        pesada há 30 anos.
                      </h1>
                      <div className="slide-brief animated hero-text">
                        <p className="text-color-white">
                          Produtos para manutenção de caminhões, ônibus,
                          máquinas agrícolas e equipamentos rodoviários.
                        </p>
                      </div>
                      <div className="btn-wrapper animated">
                        <Link
                          to={whatsapp_links.geral}
                          target="_blank"
                          className="theme-btn-1 btn btn-effect-1"
                        >
                          Entrar em contato
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 align-self-center">
                  <img src={publicUrl + "assets/img/slider/61.png"} alt="#" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
