import React from "react";
import TotalCartTable from "./components/TotalCartTable";
import { Link } from "react-router-dom";

import Cart from "./components/Cart";
const CartLayout = () => {
  return (
    <div className="liton__shoping-cart-area mb-120">
      <div className="container">
        <Cart />
        <div className="row justify-content-end">
          <div className="shoping-cart-total mt-50 justify-content-end">
            <TotalCartTable />
            <div className="btn-wrapper text-right go-top">
              <button
                type="submit"
                className="theme-btn-1 btn btn-effect-1 w-100"
              >
                <Link to="/checkout">Finalizar compra</Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CartLayout;
