import React from "react";

const OrderFilterOrderHistory = ({ onOrderChange, onStatusFilter }) => {
  const handleOrderChange = (order) => {
    onOrderChange(order);
  };
  const handleStatusChange = (status) => {
    onStatusFilter(status);
  };

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          marginTop: "10px",
          paddingBottom: "50px",
        }}
      >
        <select
          onChange={handleOrderChange}
          // value={`${productOrder.type}-${productOrder.order}`}
          className="nice-select"
        >
          <option value="ASC">Ordenar por preço Crescente</option>
          <option value="DESC">Ordenar por preço Decrescente</option>
        </select>
      </div>
      <div
        style={{
          marginTop: "10px",
          marginLeft: "10px",
          paddingBottom: "50px",
        }}
      >
        <select
          onChange={handleStatusChange}
          // value={}
          className="nice-select"
        >
          <option value="0">Todos os pedidos</option>
          <option value="1">Aguardando autorização</option>
          <option value="2">Pedido em separação</option>
          <option value="3">Pedido em aberto</option>
          <option value="4">Pedido efetuado</option>
        </select>
      </div>
    </div>
  );
};

export default OrderFilterOrderHistory;
