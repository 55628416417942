import React from "react";
import { useAuth } from "../../../hooks/useAuth";

const MyAccountLogout = () => {
  const { logout } = useAuth();

  return (
    <div className="tab-pane fade active show" id="tab_10">
      <div className="ltn__myaccount-tab-content-inner">
        <div className="ltn__comment-area mb-50">
          <div className="ltn-author-introducing clearfix">
            <div className="author-info row justify-content-center">
              <h2 className="ltn__primary-color col-12 text-align-center">
                Você tem certeza que deseja sair?
              </h2>
              <div className="col-6 text-align-center mt-3">
                <button
                  className="theme-btn-1 btn btn-block text-align-center"
                  type="submit"
                  onClick={logout}
                >
                  <i className="fas fa-sign-out-alt" />
                  Sair
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MyAccountLogout;
