import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "./../../../components/FormComponents/Input";
import { useAuth } from "../../../hooks/useAuth";
import { useHttp } from "../../../hooks/useHttp";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

const authSchema = yup.object().shape({
  email: yup
    .string()
    .email("Você precisa inserir um e-mail válido!")
    .required("Insira um e-mail válido!"),
  password: yup
    .string()
    .required("Insira uma senha!")
    .min(8, "Insira uma senha de pelo menos 8 caracteres"),
});

const AuthForm = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(authSchema) });

  const { login } = useAuth();
  const { sendRequest } = useHttp();

  const onSubmit = async (data) => {
    const responseData = await sendRequest(
      "https://matrixterra.com.br/ws/customer/login.php",
      "POST",
      JSON.stringify({
        email: data.email,
        password: data.password,
      }),
      {
        "Content-Type": "application/json",
        "Access-Control-Request-Method": "application/postscript",
      }
    );
    if (responseData.success) {
      toast.success(responseData.message, {
        position: "bottom-center",
      });
      login(
        responseData.uuid,
        responseData.token,
        responseData.cashback,
        responseData.is_user_terra
      );
      navigate("/");
      window.scrollTo(0, 0);
    } else {
      toast.error(responseData.message, {
        position: "bottom-center",
      });
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="col-md">
          <Input
            type="email"
            className={"input-item input-item-email mx-3 mb-30"}
            register={register}
            fieldName="email"
            placeholder="E-mail"
            errors={errors}
          />
        </div>
        <div className="col-md">
          <div>
            <Input
              className="input-item input-item-senha mx-3 mb-30"
              type="password"
              fieldName="password"
              register={register}
              placeholder="Senha"
              errors={errors}
            />
          </div>
        </div>
        <div className="col-md">
          <div className="btn-wrapper mt-0">
            <button
              className="btn theme-btn-1 btn-effect-1 text-uppercase mx-3"
              type="submit"
              disabled={isSubmitting}
            >
              Enviar
            </button>
          </div>
        </div>
      </form>
      <div className="col-md m-3">
        <Link to="/resetPassword">Esqueci minha senha</Link>
      </div>
    </div>
  );
};
export default AuthForm;
