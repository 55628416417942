import React, {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useAuth } from "../hooks/useAuth";
import { useHttp } from "../hooks/useHttp";
import { useNavigate } from "react-router-dom";
import useCart from "../hooks/useCart";

export const MyAccountContext = createContext({});

export const MyAccountProvider = ({ children }) => {
  const { user } = useAuth();
  const { sendRequest } = useHttp();

  const { finalizeCart } = useCart();

  const navigate = useNavigate();

  const [userData, setUserData] = useState();
  const [companyData, setCompanyData] = useState();
  const [contactTerra, setContactTerra] = useState();
  const [collaborators, setCollaborators] = useState([]);
  const [orderHistory, setOrderHistory] = useState([]);
  const [paymentDelivery, setPaymentDelivery] = useState();
  const [orderDetail, setOrderDetail] = useState(null);
  const [orderDetailId, setOrderDetailId] = useState(null);

  const fetchUserData = async () => {
    try {
      const response = await sendRequest(
        "https://matrixterra.com.br/ws/customer/myAccount.php",
        "POST",
        JSON.stringify({
          token: user.token,
        }),
        {
          "Content-Type": "application/json",
          "Access-Control-Request-Method": "application/postscript",
        }
      );
      if (response.data) {
        setUserData(response.data.my_account);
        setCompanyData(response.data.company_data);
        setContactTerra(response.data.terra_contact);
        setCollaborators(response.data.company_users);
        setOrderHistory(response.data.order_history);
        setPaymentDelivery(response.data.payment_and_delivery);
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (user) {
      fetchUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, finalizeCart]);

  const loadOrderDetail = useCallback(async (id) => {
    const response = await sendRequest(
      `https://matrixterra.com.br/ws/customer/details-order.php?id_order=${id}`,
      "GET",
      null,
      {
        "Content-Type": "application/json",
        "Access-Control-Request-Method": "application/postscript",
      }
    );
    if (response.order) {
      setOrderDetail(response.order);
    } else {
      throw new Error(response.message);
    }
  }, []);
  useEffect(() => {
    if (orderDetailId) {
      loadOrderDetail(orderDetailId);
    }
  }, [loadOrderDetail, orderDetailId, sendRequest]);

  return (
    <MyAccountContext.Provider
      value={{
        userData,
        companyData,
        contactTerra,
        collaborators,
        orderHistory,
        paymentDelivery,
        setOrderDetailId,
        orderDetail,
      }}
    >
      {children}
    </MyAccountContext.Provider>
  );
};
