import React from "react";
const AuthHeader = () => {
  return (
    <>
      <h1 className="form-text-home text-center">Faça login de cliente</h1>
      <p className="text-center mb-100">
        Use suas credenciais para acessar sua conta.
      </p>
    </>
  );
};
export default AuthHeader;
