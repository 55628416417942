import { useContext } from "react";
import { ProductContext } from "../context/ProductContext";

const useProduct = () => {
  const {
    products,
    productFilters,
    productQuery,
    isLoading,
    page,
    categories,
    search,
    setPage,
    setCategories,
    setSearch,
    productQueryHandler,
    setProductOrder,
    productOrder,
  } = useContext(ProductContext);

  return {
    products,
    productOrder,
    productQuery,
    productFilters,
    isLoading,
    page,
    categories,
    search,
    setPage,
    setCategories,
    setSearch,
    productQueryHandler,
    setProductOrder,
  };
};
export default useProduct;
