import { useContext } from "react";
import { MyAccountContext } from "../context/MyAccountContext";

export const useMyAccount = () => {
  const {
    userData,
    companyData,
    contactTerra,
    collaborators,
    orderHistory,
    paymentDelivery,
    orderDetail,
    loadOrderDetail,
    setOrderDetailId,
  } = useContext(MyAccountContext);

  return {
    userData,
    companyData,
    contactTerra,
    collaborators,
    orderHistory,
    paymentDelivery,
    loadOrderDetail,
    orderDetail,
    setOrderDetailId,
  };
};

export default useMyAccount;
