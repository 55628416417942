export const routeEnum = {
  products: "Produtos Terra",
  cart: "Carrinho",
  authentication: "Minha Conta",
  checkout: "Check-out",
  myclients: "Meus Clientes",
  myAccount: "Minha Conta",
  main: "Meu perfil",
  contact: "Meu Contato Terra",
  collab: "Perfis de Colaboradores",
  orderHistory: "Hitórico de Pedidos",
  paymentDelivery: "Pagamento e Entrega",
  requestCode: "Mude sua senha",
  logout: "Sair da conta",
  "product-detail": "Detalhes do Produto",
  finalizationCart: "Pedido finalizado",
  resetPassword: "Minha Conta",
};
