import React from "react";
import { Collaborators } from "./Collaborators";

const MyAccountCollab = () => {
  return (
    <div className="tab-pane fade active show" id="tab_3">
      <div className="ltn__myaccount-tab-content-inner">
        <Collaborators />
      </div>
    </div>
  );
};
export default MyAccountCollab;
