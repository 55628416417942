import { useCallback, useState, useEffect } from "react";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import useCart from "./useCart";

let logoutTimer;

export const useAuth = () => {
  const {
    setIsLoggedIn,
    isLoggedIn,
    setUser,
    user,
    userInfo,
    setAssistedPurchaseState,
    assistedPurchaseState,
    setUserInfo,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const { dispatch, setClientCartId, setCartId } = useCart();
  const [token, setToken] = useState();
  const [tokenExpirationDate, setTokenExpirationDate] = useState();
  const [userId, setUserId] = useState(null);
  const login = useCallback(
    (uid, token, cashback, is_user_terra, expirationDate) => {
      setToken(token);
      setUser({
        uid,
        token,
        cashback,
      });
      setUserId(uid);
      setIsLoggedIn(true);
      const tokenExpirationTime =
        expirationDate || new Date(new Date().getTime() + 8 * 60 * 60 * 1000); // now + 1 hour
      setTokenExpirationDate(tokenExpirationTime);
      localStorage.setItem(
        "userData",
        JSON.stringify({
          userId: uid,
          token: token,
          is_user_terra: is_user_terra,
          expiration: tokenExpirationTime.toISOString(),
          cashback: cashback,
        })
      );
    },
    [setIsLoggedIn, setUser]
  );
  const logout = useCallback(() => {
    setToken(false);
    setTokenExpirationDate(null);
    setUserId(null);
    setIsLoggedIn(false);
    setAssistedPurchaseState(null);
    setCartId(null);
    setUserInfo(null);
    setClientCartId(null);
    localStorage.removeItem("userData");
    dispatch({ type: "CLEAR" });
    navigate("/");
    window.scrollTo(0, 0);
  }, [
    dispatch,
    navigate,
    setAssistedPurchaseState,
    setCartId,
    setClientCartId,
    setIsLoggedIn,
    setUserInfo,
  ]);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTIme =
        tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTIme);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [logout, token, tokenExpirationDate]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"));
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(
        storedData.userId,
        storedData.token,
        storedData.cashback,
        storedData.is_user_terra,
        storedData.expirationDate
      );
    }
  }, [login]);

  return {
    login,
    logout,
    userInfo,
    isLoggedIn,
    userId,
    token,
    user,
    setAssistedPurchaseState,
    assistedPurchaseState,
    setUser,
    setUserInfo,
  };
};
