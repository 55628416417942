import React from "react";
import AboutV2 from "./section-components/about-v2";
import CallToActionV3 from "./section-components/call-to-action-v3";
import BannerV2 from "./section-components/banner-v2";
import ContactForm from "./section-components/contact-form";
import Sponsor from "./section-components/sponsor-v1";
import Phrases from "./section-components/phrases";
import ProductSliderV2 from "./section-components/product-slider-v2";

const Home_V1 = () => {
  return (
    <div>
      <BannerV2 />
      <Phrases />
      <Sponsor />
      <ProductSliderV2 />
      <CallToActionV3 />
      <AboutV2 />
      <ContactForm />
    </div>
  );
};

export default Home_V1;
