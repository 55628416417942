import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "../FormComponents/Input";
import { useHttp } from "../../hooks/useHttp";
import toast, { Toaster } from "react-hot-toast";

const phoneNumberRegexExp = new RegExp(`^[0-9]{2}[0-9]{5}[0-9]{4}$`);

const contactFormSchema = yup.object().shape({
  companyName: yup.string().required("Por favor insira o nome da empresa!"),
  companyEmail: yup
    .string()
    .email("Por favor insira um endereço de e-mail válido!")
    .required("Por favor insira um e-mail!"),
  desiredProduct: yup.string(),
  companyPhone: yup
    .string()
    .required("Por favor insira um número de telefone!")
    .matches(
      phoneNumberRegexExp,
      "Por favor insira um número de telefone válido"
    ),
  message: yup.string(),
});

const ContactForm = () => {
  const { sendRequest } = useHttp();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(contactFormSchema),
  });

  const onSubmit = async (data) => {
    const responseData = await sendRequest(
      "https://matrixterra.com.br/ws/main_contact.php",
      "POST",
      JSON.stringify({
        name: data.companyName,
        email: data.companyEmail,
        product: data.desiredProduct,
        phone: data.companyPhone,
        message: data.message,
      }),
      {
        "Content-Type": "application/json",
      }
    );
    if (responseData.success) {
      toast.success(responseData.message);
    } else {
      toast.error(responseData.message);
    }
    reset();
  };

  return (
    <div id="contact" className="ltn__contact-message-area mb-120">
      <Toaster />
      <div className="container">
        <div className="row">
          <div className="col-lg-12 m-auto">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="ltn__form-box contact-form-box box-shadow white-bg p-5">
                <h4 className="title-2 form-text-home">
                  Nos mande uma mensagem
                </h4>
                <h1 className="form-text-home">
                  Quer mais informações? Mande um e-mail!
                </h1>
                <div className="row">
                  <div className="col-md-6">
                    <Input
                      register={register}
                      className="input-item input-item-name ltn__custom-icon mb-15"
                      type="text"
                      fieldName="companyName"
                      placeholder="Insira seu nome ou o de sua empresa*"
                      errors={errors}
                    />
                  </div>
                  <div className="col-md-6">
                    <Input
                      className="input-item input-item-email ltn__custom-icon mb-15"
                      register={register}
                      type="email"
                      fieldName="companyEmail"
                      placeholder="Insira seu melhor e-mail *"
                      errors={errors}
                    />
                  </div>
                  <div className="col-md-6">
                    <Input
                      register={register}
                      type="text"
                      className="input-item input-item-arrow ltn__custom-icon mb-15"
                      fieldName="desiredProduct"
                      placeholder="Qual tipo de produto você está à procura? *"
                      errors={errors}
                    />
                  </div>
                  <div className="col-md-6">
                    <Input
                      className="input-item input-item-phone ltn__custom-icon mb-15"
                      register={register}
                      type="text"
                      fieldName="companyPhone"
                      placeholder="Insira seu telefone ou o de sua empresa *"
                      errors={errors}
                    />
                  </div>
                  <div className="col">
                    <Input
                      className="input-item input-item-textarea ltn__custom-icon mb-15"
                      textarea
                      register={register}
                      fieldName="message"
                      type="text"
                      placeholder="Insira aqui sua mensagem."
                      errors={errors}
                    />
                  </div>
                </div>
                <div className="btn-wrapper mt-0">
                  <button
                    className="btn theme-btn-1 btn-effect-1 text-uppercase"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Mandar mensagem agora!
                  </button>
                </div>
              </div>
              <p className="form-messege mb-0 mt-20" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactForm;
