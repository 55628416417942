import React from "react";
import { Link } from "react-router-dom";
import useCart from "../../../hooks/useCart";
import { hasImage } from "../../Products/components/ProductItem";
import { formatBRL } from "../../../util/formatPriceBRL";

// .toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
const SideCartItem = (props) => {
  const { dispatch } = useCart();
  const removeCartItem = () => {
    dispatch({
      type: "DELETE",
      obj: props.obj,
    });
  };
  return (
    <div className="mini-cart-item clearfix">
      <div className="mini-cart-img go-top">
        <Link to={`/product-detail/${props.obj.id}`}>
          <img
            src={hasImage(props.obj.image)}
            alt="Image"
            style={{ height: "80px" }}
          />
        </Link>
        <span onClick={removeCartItem} className="mini-cart-item-delete">
          <i className="icon-cancel" />
        </span>
      </div>
      <div className="mini-cart-info go-top">
        <h6>
          <Link to={`/product-detail/${props.obj.id}`}>
            {props.obj.product_name}
          </Link>
        </h6>
        <span className="mini-cart-quantity">
          {props.obj.quantity} x {formatBRL(props.obj.price)}
        </span>
      </div>
    </div>
  );
};
export default SideCartItem;
