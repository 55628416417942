import React from "react";
import { Link } from "react-router-dom";
import useMyAccount from "../../../../hooks/useMyAccount";

import { hasImage } from "../../../Products/components/ProductItem";

const CompanyProfile = ({ showDetailsButton }) => {
  const iconStyle = { color: "#EC1F25" };

  const { companyData } = useMyAccount();

  return (
    <div className="ltn-author-introducing clearfix">
      <div className="author-img">
        <img src={hasImage(companyData?.image)} alt="Author" />
      </div>
      <div className="author-info">
        <h6>Perfil Empresarial</h6>
        <h2 style={{ color: "#071C1F" }}>{companyData?.company_name}</h2>
        <div className="footer-address">
          <ul>
            <li>
              <div className="footer-address-icon">
                <i className="fas fa-phone fa-rotate-90" style={iconStyle} />
              </div>
              <div className="footer-address-info">
                <p>
                  <a href={`tel:${companyData?.phone}`}>{companyData?.phone}</a>
                </p>
              </div>
            </li>
            <li>
              <div className="footer-address-icon">
                <i className="far fa-envelope" style={iconStyle} />
              </div>
              <div className="footer-address-info">
                <p>
                  <a href={`mailto:${companyData?.email}`}>
                    {companyData?.email}
                  </a>
                </p>
              </div>
            </li>
            <li>
              <div className="footer-address-icon">
                <i className="fas fa-briefcase" style={iconStyle} />
              </div>
              <div className="footer-address-info">
                <p>{companyData?.company_name}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {showDetailsButton && (
          <Link to={"detailsCompany"}>
            <button
              className="theme-btn-1 btn btn-block"
              type="submit"
              style={{
                paddingLeft: "16px",
                paddingRight: "16px",
                marginLeft: "32px",
              }}
            >
              <i className="fas fa-search" />
              Ver detalhes
            </button>
          </Link>
        )}
      </div>
    </div>
  );
};
export default CompanyProfile;
