import React from "react";
import Input from "../../../../components/FormComponents/Input";
import { useForm } from "react-hook-form";
import useMyAccount from "../../../../hooks/useMyAccount";

const CompanyDetails = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const { companyData } = useMyAccount();

  return (
    <div>
      <form id="contact-form" action="mail.php" method="post">
        <div className="row">
          <div className="col-md-6 mb-3">
            <h8>Contato principal</h8>
            <div className="input-item input-item-lock ltn__custom-icon">
              <Input
                type="text"
                register={register}
                fieldName="name"
                placeholder={companyData.main_contact}
                errors={errors}
                disabled
              />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <h8>Whatsapp</h8>
            <div className="input-item input-item-lock ltn__custom-icon">
              <Input
                type="text"
                register={register}
                fieldName="phone"
                placeholder={companyData.whatsapp}
                errors={errors}
                disabled
              />
            </div>
          </div>
          <div className="col-md-6">
            <h8>Telefone</h8>
            <div className="input-item input-item-lock ltn__custom-icon">
              <Input
                type="text"
                register={register}
                fieldName="phone"
                placeholder={companyData.phone}
                errors={errors}
                disabled
              />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <h8>E-mail</h8>
            <div className="input-item input-item-lock ltn__custom-icon">
              <Input
                type="email"
                register={register}
                fieldName="email"
                placeholder={companyData.email}
                errors={errors}
                disabled
              />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <h8>Nome da Empresa</h8>
            <div className="input-item input-item-lock ltn__custom-icon">
              <Input
                type="text"
                register={register}
                fieldName="companyName"
                placeholder={companyData.company_name}
                errors={errors}
                disabled
              />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <h8>Função do contato principal</h8>
            <div className="input-item input-item-lock ltn__custom-icon">
              <Input
                type="text"
                register={register}
                fieldName="contactFunction"
                placeholder={companyData.main_contact_function}
                errors={errors}
                disabled
              />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <h8>CNPJ</h8>
            <div className="input-item input-item-lock ltn__custom-icon">
              <Input
                type="text"
                register={register}
                fieldName="cnpj"
                placeholder={companyData.cnpj}
                errors={errors}
                disabled
              />
            </div>
          </div>
        </div>
        <p className="form-messege mb-0 mt-20" />
      </form>
    </div>
  );
};

export default CompanyDetails;
