import React from "react";
import { OrderHistory } from "./OrderHistory";

const MyAccountOrderHistory = () => {
  return (
    <div className="tab-pane fade active show" id="tab_4">
      <div className="ltn__myaccount-tab-content-inner">
        <OrderHistory />
      </div>
    </div>
  );
};
export default MyAccountOrderHistory;
