import React from "react";

const CartTableHeader = () => {
  return (
    <thead>
      <tr>
        <th className="px-5">
          <h4 className="ltn__primary-color my-2 text-center ">Produto</h4>
        </th>
        <th className="px-5">
          <h4 className="ltn__primary-color my-2 text-align-left ">
            Informação
          </h4>
        </th>
        <th className="px-5">
          <h4 className="ltn__primary-color my-2 text-center ">
            Valor unidade
          </h4>
        </th>
        <th className="px-5">
          <h4 className="ltn__primary-color my-2 text-center ">Quantidade</h4>
        </th>
        <th className="px-5">
          <h4 className="ltn__primary-color my-2 text-center ">Valor total</h4>
        </th>
      </tr>
    </thead>
  );
};
export default CartTableHeader;
