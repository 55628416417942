import React from "react";

function TopBar() {
  return (
    <div>
      <div className="ltn__header-top-area section-bg-6">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ltn__top-bar-menu">
                <ul>
                  <li>
                    <a href="mailto:contato@matrixterra.com.br">
                      <i className="icon-mail" /> contato@matrixterra.com.br
                    </a>
                  </li>
                  {/* <li><a href="locations.html"><i className="icon-placeholder" /> 15/A, Nest Tower, NYC</a></li> */}
                </ul>
              </div>
            </div>
            {/* <div className="col-md-5">
							<div className="top-bar-right text-end">
								<div className="ltn__top-bar-menu">
									<ul>
										<li>
											<div className="ltn__social-media">
												<ul>
													<li><a href="#" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
													<li><a href="#" title="Instagram"><i className="fab fa-instagram" /></a></li>
												</ul>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopBar;
