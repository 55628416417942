import React, { useEffect } from "react";
import Copyright from "./copyright";

export default function Footer_v1() {
  useEffect(() => {
    const $ = window.$;

    let publicUrl = process.env.PUBLIC_URL + "/";
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/main.js";

    document.body.appendChild(minscript);

    $(".go-top")
      .find("a")
      .on("click", function () {
        $(".quarter-overlay").fadeIn(1);

        $(window).scrollTop(0);

        setTimeout(function () {
          $(".quarter-overlay").fadeOut(300);
        }, 800);
      });

    $(document).on("click", ".theme-btn-1 ", function () {
      $("div").removeClass("modal-backdrop");
      $("div").removeClass("show");
      $("div").removeClass("fade");
      $("body").attr("style", "");
    });
  }, []);

  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <footer className="ltn__footer-area  ">
      <div className="footer-top-area  section-bg-2 plr--5">
        <div className="container-fluid">
          <div className="row d-flex justify-content-center">
            <div className="col-xl-4 col-md-4 col-sm-6 col-12">
              <div className="footer-widget footer-about-widget">
                <div className="footer-logo">
                  <div className="site-logo">
                    <img
                      src={publicUrl + "assets/img/logo-footer.png"}
                      alt="Logo"
                    />
                  </div>
                </div>
                <p>Grupo Terra - Gente feliz fazendo bons negócios!</p>
                <div className="footer-address">
                  <ul>
                    <li>
                      <div className="footer-address-icon colored-footer-icon">
                        <i class="fab fa-whatsapp"></i>
                      </div>
                      <div className="footer-address-info">
                        <p>
                          <a href="tel:+51985530391">(51) 98553-0391</a>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="footer-address-icon">
                        <i class="fa-solid fa-phone"></i>
                      </div>
                      <div className="footer-address-info">
                        <p>
                          <a href="tel:+5134382127">(51) 3438-2127</a>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="footer-address-icon">
                        <i class="fa-solid fa-envelope"></i>
                      </div>
                      <div className="footer-address-info">
                        <p>
                          <a href="mailto:vendas@matrixterra.com.br">
                            vendas@matrixterra.com.br
                          </a>
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="footer-address-icon">
                        <i class="fa-solid fa-envelope"></i>
                      </div>
                      <div className="footer-address-info">
                        <p>
                          <a href="mailto:adm@matrixterra.com.br">
                            adm@matrixterra.com.br
                          </a>
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-4 col-sm-6 col-12">
              <div className="footer-widget footer-menu-widget clearfix">
                <h4 className="footer-title">Nossas áreas</h4>
                <div className="footer-menu">
                  <ul>
                    <li>
                      <a href="#home">Home</a>
                    </li>
                    <li>
                      <a href="#products">Produtos</a>
                    </li>
                    <li>
                      <a href="#about-us">Sobre nós</a>
                    </li>
                    <li>
                      <a href="#contact">Contato</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className="col-xl-2 col-md-6 col-sm-6 col-12">
							<div className="footer-widget footer-menu-widget clearfix">
								<h4 className="footer-title">Services</h4>
								<div className="footer-menu go-top">
									<ul>
										<li><Link to="/cart">Cart</Link></li>
										<li><Link to="/wishlist">Wish List</Link></li>
										<li><Link to="/login">Login</Link></li>
										<li><Link to="/checkout">Checkout</Link></li>
										<li><Link to="/about">Terms &amp; Conditions</Link></li>
										<li><Link to="/shop">Promotional Offers</Link></li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-xl-2 col-md-6 col-sm-6 col-12">
							<div className="footer-widget footer-menu-widget clearfix">
								<h4 className="footer-title">Customer Care</h4>
								<div className="footer-menu go-top">
									<ul>
										<li><Link to="/login">Login</Link></li>
										<li><Link to="/my-account">My account</Link></li>
										<li><Link to="/wishlist">Wish List</Link></li>
										<li><Link to="/add-listing">Add listing</Link></li>
										<li><Link to="/faq">FAQ</Link></li>
										<li><Link to="/contact">Contact us</Link></li>
									</ul>
								</div>
							</div>
						</div> */}
            <div className="col-xl-4 col-md-4 col-sm-12 col-12">
              <div className="footer-widget footer-newsletter-widget">
                <h4 className="footer-title">
                  Seja o primeiro a saber de novos produtos e mais!
                </h4>
                <p>
                  Se inscreva na nossa assinatura de e-mail para receber
                  notícias sobre produtos e outras novidades.
                </p>
                <div className="footer-newsletter">
                  <form>
                    <input type="email" name="email" placeholder="Email*" />
                    <div className="btn-wrapper">
                      <button className="theme-btn-1 btn" type="submit">
                        <i className="fas fa-location-arrow" />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Copyright />
    </footer>
  );
}
