import React from "react";
import Banner from "./Banner";

const LayoutBanner = ({ children }) => {
  return (
    <div>
      <Banner />
      {children}
    </div>
  );
};
export default LayoutBanner;
