import React from "react";
import { Link, useLocation } from "react-router-dom";
import { routeEnum } from "../util/RouteEnum";
import { useAuth } from "../hooks/useAuth";
const Banner = (props) => {
  const location = useLocation();
  const local = location.pathname;
  const pathnameArray = local.split("/");
  pathnameArray.shift();
  const { assistedPurchaseState } = useAuth();

  let HeaderTitle = props.headertitle;
  let publicUrl = process.env.PUBLIC_URL + "/";
  let Subheader = props.subheader ? props.subheader : HeaderTitle;
  let CustomClass = props.customclass ? props.customclass : "";
  let Img = props.Img ? props.Img : "14.jpg";

  return (
    <>
      <div
        className={
          "ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image" +
          CustomClass
        }
        style={{
          marginBottom: assistedPurchaseState ? "0px" : "120px",
          backgroundImage: `url(${publicUrl}assets/img/bg/14.jpg)`,
        }}
        data-bs-bg={publicUrl + "assets/img/bg/14.jpg"}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__breadcrumb-inner ">
                <h1 className="page-title ltn__primary-color">
                  {routeEnum[pathnameArray[0]]}
                </h1>
                <div className="ltn__breadcrumb-list">
                  <ul>
                    <li>
                      <Link to="/" className="ltn__primary-color">
                        <span className="ltn__secondary-color">
                          <i className="fas fa-home" />
                        </span>{" "}
                        Home
                      </Link>
                    </li>
                    {pathnameArray.map((e, i) => (
                      <li
                        className={
                          i + 1 === pathnameArray.length
                            ? "ltn__secondary-color"
                            : "ltn__primary-color"
                        }
                        key={e}
                      >
                        {routeEnum[e] || e}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {assistedPurchaseState && (
        <div
          style={{
            display: "flex",
            background: "#212529",
            justifyContent: "center",
            marginBottom: "120px",
            alignItems: "center",
            color: "white",
            height: "70px",
          }}
        >
          Atenção! Você está ao vivo na conta do colaborador{" "}
          {assistedPurchaseState.clientName}.
        </div>
      )}
    </>
  );
};

export default Banner;
