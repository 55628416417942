import React from "react";
import AuthForm from "./components/AuthForm";
import AuthInfo from "./components/AuthInfo";
import AuthHeader from "./components/AuthHeader";
const Auth = () => {
  return (
    <>
      <div className="container">
        <AuthHeader />
        <div className="row row-cols-lg-2 row-cols-sm-1 mb-5">
          <AuthForm />
          <AuthInfo />
        </div>
      </div>
    </>
  );
};
export default Auth;
