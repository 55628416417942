import React, { memo, useState } from "react";
import { Link } from "react-router-dom";
import SideCart from "./../../pages/SideCart";
import useCart from "../../hooks/useCart";
import { useAuth } from "../../hooks/useAuth";
import { useMediaQuery } from "@react-hook/media-query";
import { whatsapp_links } from "../../data/contact";
import MobileMenu from "../MobileMenu";

const MainNavbar = () => {
  const { isLoggedIn, userInfo } = useAuth();

  const { cart } = useCart();
  const { logout } = useAuth();
  const publicUrl = process.env.PUBLIC_URL + "/";
  const logoUrl = `${publicUrl}assets/img/logo.png`;

  const isSmallScreen = useMediaQuery("(max-width: 431px)");
  const isMediumScreen = useMediaQuery("(max-width: 1180px)");

  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [openCart, setOpenCart] = useState(false);

  const whatsappLink = whatsapp_links.geral;

  const whatsappButton = () => {
    window.open(whatsappLink, "_blank");
  };

  const handleOpenMobileMenu = () => {
    setOpenMobileMenu(true);
  };

  const handleCloseMobileMenu = () => {
    setOpenMobileMenu(false);
  };

  const handleOpenCart = () => {
    setOpenCart(true);
  };
  const handleCloseCart = () => {
    setOpenCart(false);
  };

  return (
    <div>
      <header className="ltn__header-area ltn__header-5 ltn__header-transparent--- gradient-color-4---">
        <div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-white">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-6 col-lg-3">
                <div className="site-logo-wrap">
                  <div
                    className="site-logo go-top"
                    style={{
                      marginRight: isMediumScreen && !isSmallScreen && "100px",
                    }}
                  >
                    <Link to="/">
                      <img src={logoUrl} alt="Logo" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-6">
                <div className="ltn__header-options ltn__header-options-2 mb-sm-20 m-0 justify-content-end">
                  <div className="header-menu">
                    <nav>
                      <div className="ltn__main-menu go-top">
                        <ul className="justify-content-end">
                          {!isMediumScreen && (
                            <>
                              <li>
                                <Link to="/">Página Inicial</Link>
                              </li>
                              <li>
                                <Link to="/products">Produtos</Link>
                              </li>
                            </>
                          )}
                        </ul>
                      </div>
                    </nav>
                  </div>
                  <div className="col-6 col-lg-3">
                    <div className="ltn__header-options ltn__header-options-2 mb-sm-20 m-0 justify-content-end">
                      {!isMediumScreen && (
                        <>
                          <Link
                            to={
                              isLoggedIn
                                ? userInfo?.is_user_terra
                                  ? "/myclients"
                                  : "/myAccount/main"
                                : "/authentication"
                            }
                          >
                            <button
                              className={`theme-btn-4 btn btn-effect-1  ${
                                isLoggedIn && "ltn__drop-menu"
                              } user-menu`}
                              style={{
                                boxShadow: "var(--ltn__box-shadow-1)",
                                border: "none",
                                padding: "12px 16px",
                                marginRight: "8px",
                                minWidth: isLoggedIn ? "50px" : "100px",
                              }}
                            >
                              {isLoggedIn ? (
                                <i className="icon-user" />
                              ) : (
                                <>
                                  <i className="fa-regular fa-right-to-bracket"></i>{" "}
                                  Login
                                </>
                              )}
                            </button>
                          </Link>
                        </>
                      )}
                      {isMediumScreen && !isSmallScreen && (
                        <button
                          className="theme-btn-4 btn btn-effect-1"
                          style={{
                            boxShadow: "var(--ltn__box-shadow-1)",
                            border: "none",
                            padding: "12px 16px",
                          }}
                          onClick={whatsappButton}
                        >
                          <i
                            className="fa-brands fa-whatsapp"
                            style={{ margin: 0 }}
                          ></i>
                        </button>
                      )}
                      {isLoggedIn && (
                        <button
                          className="theme-btn-4 btn btn-effect-1"
                          style={{
                            boxShadow: "var(--ltn__box-shadow-1)",
                            border: "none",
                            padding: "12px 16px",
                          }}
                          onClick={handleOpenCart}
                        >
                          <i className="icon-shopping-cart"></i>
                          {cart.length !== 0 && isLoggedIn && (
                            <sup>{cart.length}</sup>
                          )}
                        </button>
                      )}
                      {isLoggedIn &&
                        userInfo?.is_user_terra &&
                        isMediumScreen &&
                        !isSmallScreen && (
                          <Link to="/myclients">
                            <button
                              className="theme-btn-4 btn btn-effect-1"
                              style={{
                                boxShadow: "var(--ltn__box-shadow-1)",
                                border: "none",
                                padding: "12px 16px",
                              }}
                            >
                              <i className="icon-user" />
                            </button>
                          </Link>
                        )}
                      {isLoggedIn &&
                        userInfo?.is_user_terra &&
                        isMediumScreen &&
                        !isSmallScreen && (
                          <Link to="/myclients">
                            <button
                              className="theme-btn-4 btn btn-effect-1"
                              style={{
                                boxShadow: "var(--ltn__box-shadow-1)",
                                border: "none",
                                padding: "12px 16px",
                                marginLeft: "12px",
                              }}
                            >
                              <i class="fa-solid fa-shop"></i>
                            </button>
                          </Link>
                        )}
                    </div>
                  </div>

                  {!isMediumScreen && (
                    <>
                      {userInfo?.is_user_terra ? (
                        <button
                          className="theme-btn-1 btn btn-effect-1"
                          onClick={logout}
                          style={{
                            padding: "12px",
                            minWidth: "150px",
                            boxShadow: "var(--ltn__box-shadow-1)",
                            border: "none",
                          }}
                        >
                          <i className="fas fa-sign-out-alt" />
                          Sair
                        </button>
                      ) : (
                        <>
                          {isLoggedIn && (
                            <button
                              className="theme-btn-white btn btn-effect-1"
                              onClick={logout}
                              style={{
                                padding: "14px 8px",
                                minWidth: "180px",
                                boxShadow: "var(--ltn__box-shadow-1)",
                                border: "none",
                              }}
                            >
                              <i className="fas fa-sign-out-alt" /> Sair da
                              conta
                            </button>
                          )}
                          <button
                            className="theme-btn-1 btn btn-effect-1"
                            style={{
                              padding: "14px 8px",
                              minWidth: "180px",
                              boxShadow: "var(--ltn__box-shadow-1)",
                              border: "none",
                            }}
                            onClick={whatsappButton}
                          >
                            <i className="fa-brands fa-whatsapp"></i>
                            Entre em contato
                          </button>
                        </>
                      )}
                    </>
                  )}
                  {isMediumScreen && (
                    <>
                      {userInfo?.is_user_terra ? (
                        <button
                          className="theme-btn-1 btn btn-effect-1"
                          onClick={logout}
                          style={{
                            padding: "12px",
                            minWidth: "80px",
                            boxShadow: "var(--ltn__box-shadow-1)",
                            border: "none",
                          }}
                        >
                          <i className="fas fa-sign-out-alt" style={{}} />
                          Sair
                        </button>
                      ) : (
                        <button
                          className="theme-btn-1 btn btn-effect-1"
                          style={{
                            boxShadow: "var(--ltn__box-shadow-1)",
                            border: "none",
                            padding: "12px 16px",
                          }}
                          onClick={handleOpenMobileMenu}
                        >
                          <i
                            className="fa-solid fa-bars"
                            style={{
                              margin: 0,
                            }}
                          />
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <SideCart handleCloseCart={handleCloseCart} isOpen={openCart} />

      <MobileMenu
        isOpen={openMobileMenu}
        onWhatsappButton={whatsappButton}
        handleCloseButton={handleCloseMobileMenu}
      />
    </div>
  );
};

export default memo(MainNavbar);
