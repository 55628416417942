import React, { useRef } from "react";
import { useHttp } from "../../hooks/useHttp";
import toast from "react-hot-toast";

const ValidateCode = ({ stepHandler, emailSelected, userIdHandler }) => {
  const { sendRequest } = useHttp();

  const tokenRef = useRef();

  const ValidateCode = async () => {
    const responseData = await sendRequest(
      "https://matrixterra.com.br/ws/customer/forgotPassword/check-code-forgot-password.php",
      "POST",
      JSON.stringify({
        verification_token: tokenRef.current.value,
        email: emailSelected,
      }),
      {
        "Content-Type": "application/json",
      }
    );
    if (responseData.success) {
      stepHandler("3");
      userIdHandler(responseData.user_id);
      toast.success("Token validado com sucesso!");
    } else {
      toast.error(responseData.message);
      //   throw new Error(responseData.message);
    }
  };

  const submitHandler = async () => {
    ValidateCode();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1 className="form-text-home text-center" style={{ maxWidth: "690px" }}>
        Um código de recuperação foi enviado para seu email!
      </h1>
      <p className="text-center mb-50">
        Insira abaixo o código para continuar a alteração de senha.
      </p>
      <input
        className="input-item input-item-email px-3 "
        style={{
          width: "100%",
          maxWidth: "469px",
          textAlign: "center",
          fontSize: "20px",
        }}
        placeholder="Código de recuperação de senha*"
        ref={tokenRef}
      />
      <div className="btn-wrapper mt-0">
        <button
          className="btn theme-btn-1 btn-effect-1 text-uppercase"
          type="submit"
          onClick={submitHandler}
          style={{
            maxWidth: "100%",
            width: "469px",
            marginTop: "30px",
            marginBottom: "30px",
          }}
          // disabled={isSubmitting}
        >
          Continuar
        </button>
      </div>
    </div>
  );
};
export default ValidateCode;
