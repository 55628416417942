import React from "react";
import { NavLink, useLocation } from "react-router-dom";

export const Menu = () => {
  const pathRoot = "/myAccount";
  const location = useLocation();

  const componentArray = [
    {
      label: "Minha Conta",
      path: "/main",
      icon: <i className="fas fa-user" />,
    },
    {
      label: "Meu Contato Terra",
      path: "/contact",
      icon: <i className="fas fa-briefcase" />,
    },
    {
      label: "Perfis de Colaboradores",
      path: "/collab",
      icon: <i className="fas fa-users" />,
    },
    {
      label: "Histórico de Pedidos",
      path: "/orderHistory",
      icon: <i className="fa-solid fa-list" />,
    },
    {
      label: "Pagamento e Entrega",
      path: "/paymentDelivery",
      icon: <i className="fa-solid fa-money-check-dollar" />,
    },
    {
      label: "Mude sua senha",
      path: "/requestCode",
      icon: <i className="fa-solid fa-lock" />,
    },
    {
      label: "Sair da conta",
      path: "/logout",
      icon: <i className="fas fa-sign-out-alt" />,
    },
  ];

  return (
    <>
      <div className="nav">
        {location.pathname.includes("collabDetails") && (
          <NavLink to={`${pathRoot}/collab`} style={{ background: "#000" }}>
            <i className="fas fa-arrow-left" />
            Voltar
          </NavLink>
        )}
        {location.pathname.includes("detailsCompany") && (
          <NavLink to={`${pathRoot}/main`} style={{ background: "#000" }}>
            <i className="fas fa-arrow-left" />
            Voltar
          </NavLink>
        )}
        {componentArray.map((item, index) => (
          <NavLink key={index} to={`${pathRoot}${item.path}`}>
            {item.label}
            {item.icon}
          </NavLink>
        ))}
      </div>
    </>
  );
};
