import React from "react";
import ProductFilterItem from "./ProductFilterItem";
import useProduct from "../../../hooks/useProduct";

const ProductFilters = () => {
  const { productFilters, isLoading } = useProduct();

  return (
    <div className="col-lg-3 col-sm-12">
      <div className="ltn__form-box box-shadow white-bg p-4">
        <h4 className="form-text-home text-left mb-5 ">Categoria de Produto</h4>
        {isLoading ? (
          <div
            style={{
              height: "400px",
            }}
          >
            <h4
              style={{
                color: "gray",
                height: "400px",
                textAlign: "center",
              }}
            >
              Carregando categorias...
            </h4>
          </div>
        ) : (
          productFilters.map((e) => (
            <ProductFilterItem key={e.id} name={e.name} id={e.id} />
          ))
        )}
      </div>
    </div>
  );
};
export default ProductFilters;
