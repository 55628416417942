import React from "react";
import styles from "./PaymentDelivery.module.css";
import { useForm } from "react-hook-form";
import Input from "../../../../components/FormComponents/Input";
import useMyAccount from "../../../../hooks/useMyAccount";

export const PaymentDelivery = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const { paymentDelivery, contactTerra } = useMyAccount();

  return (
    <div className="ltn__myaccount-tab-content-inner ">
      <p style={{ margin: "0" }}>
        Caso queira modificar alguma informação, mande um e-mail para
        adm@matrixterra.com.br ou nos contate por {contactTerra?.whatsapp}.
      </p>
      <div className="row">
        <div className="col-lg-12">
          <div className="ltn__checkout-inner">
            <div className="ltn__checkout-single-content ltn__returning-customer-wrap">
              <div id="ltn__returning-customer-login"></div>
            </div>
            <div className="ltn__checkout-single-content">
              <h4 className="title-2">Informações de compra</h4>
              <div>
                <form action="#">
                  <h6>Informações do comprador</h6>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <h8>Responsável</h8>
                      <div className="input-item input-item-lock ltn__custom-icon">
                        <Input
                          type="text"
                          register={register}
                          fieldName="ltn__name"
                          placeholder={paymentDelivery?.master_user_name}
                          errors={errors}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <h8>Empresa</h8>
                      <div className="input-item input-item-lock ltn__custom-icon">
                        <Input
                          type="text"
                          register={register}
                          fieldName="ltn__company"
                          placeholder={paymentDelivery?.fantasy_name}
                          errors={errors}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <h8>Whatsapp</h8>
                      <div className="input-item input-item-lock ltn__custom-icon">
                        <Input
                          type="text"
                          register={register}
                          fieldName="ltn__phone"
                          placeholder={paymentDelivery?.whatsapp}
                          errors={errors}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <h8>Telefone</h8>
                      <div className="input-item input-item-lock ltn__custom-icon">
                        <Input
                          type="text"
                          register={register}
                          fieldName="ltn__phone"
                          placeholder={paymentDelivery?.phone}
                          errors={errors}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-3">
                      <h8>E-mail</h8>
                      <div className="input-item input-item-lock ltn__custom-icon">
                        <Input
                          type="text"
                          register={register}
                          fieldName="ltn__email"
                          placeholder={paymentDelivery?.corporate_email}
                          errors={errors}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <h6 className="mt-3">Endereço de entrega</h6>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <h8>Endereço</h8>
                          <div className="input-item input-item-lock ltn__custom-icon">
                            <Input
                              type="text"
                              register={register}
                              fieldName="address"
                              placeholder={paymentDelivery?.address}
                              errors={errors}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <h8>Complemento</h8>
                          <div className="input-item input-item-lock ltn__custom-icon">
                            <Input
                              type="text"
                              register={register}
                              fieldName="addressComplement"
                              placeholder={paymentDelivery?.complement}
                              errors={errors}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-3">
                      <h8>Estado</h8>
                      <div className="input-item input-item-lock ltn__custom-icon">
                        <Input
                          type="text"
                          register={register}
                          fieldName="state"
                          placeholder={paymentDelivery?.state}
                          errors={errors}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-3">
                      <h8>Cidade</h8>
                      <div className="input-item input-item-lock ltn__custom-icon">
                        <Input
                          type="text"
                          register={register}
                          fieldName="city"
                          placeholder={paymentDelivery?.city}
                          errors={errors}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <h8>CEP</h8>
                      <div className="input-item input-item-lock ltn__custom-icon">
                        <Input
                          register={register}
                          fieldName="zipCode"
                          type="text"
                          placeholder={paymentDelivery?.zip}
                          errors={errors}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-lg-8">
          <div className="ltn__checkout-payment-method">
            <h4 className="title-2">Método de pagamento selecionado</h4>
            <div id="checkout_accordion_1">
              <div>
                <button
                  className={`btn ${
                    selectedButton === "boleto14"
                      ? styles.selected
                      : styles.button
                  }`}
                  style={{ width: "300px" }}
                  onClick={() => handleButtonClick("boleto14")}
                >
                  Boleto de 14 dias{" "}
                </button>
              </div>
              <div>
                <button
                  className={`btn ${
                    selectedButton === "boleto28"
                      ? styles.selected
                      : styles.button
                  }`}
                  style={{ width: "300px" }}
                  onClick={() => handleButtonClick("boleto28")}
                >
                  Boleto de 28 dias
                </button>
              </div>
              <div>
                <button
                  className={`btn ${
                    selectedButton === "boleto45"
                      ? styles.selected
                      : styles.button
                  }`}
                  style={{ width: "300px" }}
                  onClick={() => handleButtonClick("boleto45")}
                >
                  Boleto de 45 dias
                </button>
              </div>
              <div>
                <button
                  className={`btn ${
                    selectedButton === "aVista"
                      ? styles.selected
                      : styles.button
                  }`}
                  style={{ width: "300px" }}
                  onClick={() => handleButtonClick("aVista")}
                >
                  À vista com desconto
                </button>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};
