import React from "react";
import { Link } from "react-router-dom";

const Finalization = () => {
  return (
    <div
      className="container"
      style={{
        display: "flex",
        padding: "3rem 0px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <i
        class="fa-solid fa-circle-check primary"
        style={{ fontSize: "150px", color: "#77C720" }}
      ></i>
      <h1 className="ltn__primary-color m-3">Senha Alterada!</h1>
      <p style={{ margin: "20px", textAlign: "center" }}>
        Agora você pode fazer seu login e continuar a aproveitar as vantagens de
        uma conta Terra :)
      </p>
      <Link
        to="/authentication"
        className="theme-btn-1 btn btn-effect-1"
        style={{
          maxWidth: "300px",
        }}
      >
        <i class="fa-solid fa-arrow-left"></i>
        Voltar para o Login
      </Link>
    </div>
  );
};

export default Finalization;
